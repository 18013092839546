import { useRef, useState } from 'react';
import { Grid, InputBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OutlinedFlagRounded } from '@mui/icons-material';
import { PlacesSearchBar } from '../../SearchBar';
import classList from '../../../classList';
import InlineBlade from '../../InlineBlade';
import { useMapUtils } from '../../../organisms/MapUtils';
import { parseISODate } from '../../../../utils';

const useTodoItemStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D9D9D9',
    borderRadius: 4,
    display: 'flex',
    padding: '3px 0.5%',
    margin: '8px 0% 0% 1%',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    cursor: 'pointer',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  activityIcon: ({
    isActivityHovered,
    isActivityFocused,
    isActivityHighlight,
  }) => ({
    color: isActivityFocused
      ? palette.primary.main
      : isActivityHovered || isActivityHighlight
      ? palette.primary.light
      : '#222',
  }),
  inputContainer: {
    display: 'flex',
    flex: 1,
    marginLeft: '8px',
  },
  hovered: {
    border: `1px solid ${palette.primary.light}`,
  },
  spacingRight: {
    paddingRight: '0rem',
  },
  todoItem: {
    position: 'relative',
    marginLeft: 'calc(3.6rem - 15px)',
    // marginRight: 12,
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 15px)',
      marginRight: 12,
    },
  },
  activityBladeIconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  activityBladeIcon: {
    fontSize: '16px',
    color: '#8A8A8A',
  },
}));

function TodoItemImport({
  todo,
  setItem,
  openBlade: openInlineBlade,
  setOpenBlade: setOpenInlineBlade,
}) {
  const maxWidth = '80px';
  const classes = useTodoItemStyles({ maxWidth });
  const { place, setPlace, handleLocationSelect } = useMapUtils();

  const inputRef = useRef();
  const timeRef = useRef(null);
  const delayRef = useRef();

  // const [openInlineBlade, setOpenInlineBlade] = useState(false);
  const [isActivityFocused, setActivityFocused] = useState(false);
  const [isActivityHovered, setActivityHovered] = useState(false);
  const [enterTrigger, setEnterTrigger] = useState(false);
  const [title, setTitle] = useState(todo?.title);
  const [notes, setNotes] = useState(todo?.description);
  const [cost, setCost] = useState(todo?.cost);
  const [currency, setCurrency] = useState(todo?.currency);
  const [costPer, setCostPer] = useState(todo?.costPer);
  const [address, setAddress] = useState(todo?.streetAddress);
  const [link, setLink] = useState(todo?.links);
  const [date, setDate] = useState(
    parseISODate(todo?.activityTime, todo?.version)
  );

  // function to dynamically update activity title
  const updateActivityTitle = () => {};

  const handleUpdate = (updatedAttributes) => {
    setItem({
      ...todo,
      ...updatedAttributes,
    });
  };

  const handleLinkUpdate = (newLink) => {
    setLink(newLink);
    handleUpdate({
      links: newLink,
    });
  };

  const handleLocationUpdate = async ({
    streetAddress,
    // city,
    // state,
    // country,
    // zipCode,
    // mapPin: newMapPin,
    // title: newTitle,
    // mapPin,
    // title,
  }) => {
    setAddress(streetAddress);
    handleUpdate({
      streetAddress,
    });
  };

  const handleTitleUpdate = async (newTitle) => {
    if (newTitle === '') return;
    setTitle(newTitle);
    handleUpdate({
      title: newTitle,
    });
  };

  // function to hover the associated map pin on activity hover
  const hoverPin = () => {};

  const handleDateUpdate = (newDate) => {
    // const ISODate = removeTimezoneOffset(newDate)?.toISOString();
    setDate(newDate);
    handleUpdate({
      activityTime: newDate,
    });
  };

  const handleCostUpdate = (newCost) => {
    const {
      amount: costAmount,
      currency: costCurrency,
      per: costPerOption,
    } = newCost;
    setCost(costAmount);
    setCurrency(costCurrency);
    setCostPer(costPerOption);

    handleUpdate({
      cost: costAmount,
      currency: costCurrency,
      costPer: costPerOption,
    });
  };

  const handleNotesUpdate = (newNotes) => {
    setNotes(newNotes);
    handleUpdate({
      description: newNotes,
    });
  };

  return (
    <div className="activity-blade-container">
      <Grid
        item
        container
        xs={12}
        className={`${classes.spacingRight} ${classList.todoItem}`}>
        <div
          className={classList.item}
          id="activity-blade-close"
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          {!openInlineBlade ? (
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
              <Grid
                className={`
                  activity-blade-in-container
                  ${classes.root}
                  ${isActivityHovered ? classes.hovered : null}
                `}
                style={{
                  marginLeft: 0,
                }}
                onMouseEnter={() => {
                  setActivityHovered(true);
                  hoverPin(true);
                }}>
                <OutlinedFlagRounded
                  style={{ fontSize: 16, marginLeft: 4 }}
                  className={classes.activityIcon}
                />
                <Grid item className={classes.inputContainer}>
                  <PlacesSearchBar
                    isActive={isActivityFocused && title?.length > 2}
                    // locationBias={locationBias}
                    tripLocation={false}
                    popperPlacement="bottom"
                    CustomSearchBar={InputBase}
                    inputRef={inputRef}
                    handleSelect={async (option) => {
                      const location = await handleLocationSelect(
                        option.place_id,
                        undefined,
                        'ACTIVITY'
                      );
                      setEnterTrigger(true);
                      setActivityFocused(false);
                      setActivityHovered(false);
                      hoverPin(false);
                      if (!delayRef.current) {
                        await updateActivityTitle('Enter', location);
                      } else {
                        delayRef.current = null;
                      }
                      inputRef?.current?.blur();
                    }}
                    value={title}
                    onChange={(newTitle) => setTitle(newTitle)}
                    searchBarProps={{
                      autoComplete: 'off',
                      placeholder: 'new activity',
                      onFocus: () => {
                        setActivityFocused(true);
                      },
                      onBlur: async () => {
                        timeRef.current = setTimeout(async () => {
                          if (!enterTrigger) {
                            setActivityFocused(false);
                            setActivityHovered(false);
                            hoverPin(false);
                            await updateActivityTitle('Blur');
                          } else {
                            setEnterTrigger(false);
                          }
                        }, 250); // Delay to ensure button click is processed first
                      },
                      onKeyUp: async (e) => {
                        if (e.key === 'Enter' || e.key === 'Escape') {
                          setEnterTrigger(true);
                          setActivityFocused(false);
                          setActivityHovered(false);
                          hoverPin(false);
                          delayRef.current = true;
                          await updateActivityTitle('Enter');
                        }
                      },
                      onClick: () => {
                        if (title !== '') {
                          setOpenInlineBlade(true);
                          setActivityFocused(false);
                          setActivityHovered(false);
                          hoverPin(false);
                        }
                      },

                      inputProps: {
                        style: { padding: 3, cursor: 'pointer' },
                      },
                    }}
                  />
                </Grid>
                {/* <Grid className={classes.activityBladeIconContainer}>
                  {previewIcons}
                </Grid> */}
              </Grid>
            </Grid>
          ) : (
            <div
              style={{
                margin: '8px 0% 0% 0px',
              }}>
              <InlineBlade
                className="activity-inlineblade-container"
                open={openInlineBlade}
                // id={id}
                // locationBias={locationBias}
                setOpen={async (isOpen) => {
                  setOpenInlineBlade(isOpen);
                  if (!isOpen && todo?.mapPin?.id === place?.pinId) {
                    setPlace(null);
                  }
                  setOpenInlineBlade(isOpen);
                  if (!isOpen)
                    await updateActivityTitle('Close', {
                      cost,
                      currency,
                      costPer: Number(costPer),
                      activityTime: date,
                      description: notes,
                      streetAddress: address,
                      links: link,
                      files: todo?.files,
                    });
                  //   if (isMobile) {
                  //     setActivityHovered(isOpen);
                  //     setActivityFocused(isOpen);
                  //   }
                }}
                name={title}
                updateNameCallback={handleTitleUpdate}
                notes={notes}
                updateNotesCallback={handleNotesUpdate}
                hasDate
                dateVal={date}
                updateDateCallback={handleDateUpdate}
                hasCost
                costVal={{
                  amount: cost,
                  currency,
                  per: costPer,
                }}
                updateCostCallback={handleCostUpdate}
                hasLink
                linkVal={link}
                updateLinkCallback={handleLinkUpdate}
                isActivity
                hasLocation
                updateLocationCallback={handleLocationUpdate}
                address={address}
                mapPinId={todo?.mapPin}
                fileAttachmentModalProps={{
                  attachedToText: title,
                  attachedToID: '',
                  attachedToType: 'Activity',
                  attachedFiles: todo?.files,
                  attachFunc: (newFiles, tripid) => {
                    handleUpdate(
                      {
                        files: newFiles,
                      },
                      {
                        attachedToType: 'Activity',
                        tripId: tripid,
                      }
                    );
                  },
                }}
                // isNewActivity={todo?.id.includes('local-todo')}
                // addActivityBelow={addLocalActivity}
                activityVersion={todo?.version}
              />
            </div>
          )}
        </div>
      </Grid>
    </div>
  );
}

export default TodoItemImport;
