import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  List,
  ListItemText,
  Typography,
  ListItem,
  ListItemIcon,
} from '@mui/material';
import { OutlinedFlagOutlined } from '@mui/icons-material';
import { BedIcon } from '../../../atoms/Icon';
import { EVENTS, phTrackEvent } from '../../../../analytics';

const useStyles = makeStyles({
  headers: {
    marginLeft: '8px',
    fontSize: '12px',
    color: '#aaaaaa',
    fontWeight: 150,
  },
  listItemContainer: {
    height: '40px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f4f4',
      borderRadius: '8px',
      color: '#000000',
    },
    padding: '8px 8px',
  },
  listItemLetter: {
    fontWeight: 100,
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  focusedItem: {
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
    color: '#000000',
  },
});

function SuggestIdeas({ setActiveSection, value, setSuggestion }) {
  const classes = useStyles();
  const [focusedIndex, setFocusedIndex] = useState(0);

  const suggestIdeas = [
    {
      icon: (
        <BedIcon
          style={{
            width: '28px',
            height: '28px',
            marginLeft: '4px',
            color: '#8A8A8A',
          }}
        />
      ),
      text: 'Hotels',
      shortcut: 'Coming Soon',
      handleButtonClick: () => {},
      enabled: false,
    },
    {
      icon: (
        <OutlinedFlagOutlined
          sx={{
            width: '24px',
            height: '24px',
            marginLeft: '5px',
            color: '#000000',
          }}
        />
      ),
      text: 'Activities',
      // shortcut needs rework
      shortcut: '',
      handleButtonClick: () => {
        phTrackEvent({
          event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_ACTIVITIES_OPEN,
        });
        phTrackEvent({
          event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_ACTIVITIES_CLICK,
        });
        setActiveSection('suggestActivities');
        setSuggestion('Activities');
      },
      enabled: true,
    },
  ];

  const filteredOptions = suggestIdeas.filter((option) => {
    const trimmedValue = value.trim().toLowerCase();

    if (trimmedValue !== 'suggest ideas') {
      setSuggestion('');
    }
    // If the value starts with 'Suggest Ideas', ignore that part
    const searchValue = trimmedValue.startsWith('suggest ideas')
      ? trimmedValue.replace('suggest ideas', '').trim()
      : trimmedValue;

    // Perform filtering with the remaining text
    return option.text.toLowerCase().includes(searchValue);
  });

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      setFocusedIndex((prevIndex) =>
        prevIndex === filteredOptions.length - 1 ? 0 : prevIndex + 1
      );
    } else if (event.key === 'ArrowUp') {
      setFocusedIndex((prevIndex) =>
        prevIndex === 0 ? filteredOptions.length - 1 : prevIndex - 1
      );
    } else if (event.key === 'Enter') {
      if (filteredOptions[focusedIndex]) {
        filteredOptions[focusedIndex].handleButtonClick();
      }
      event.preventDefault();
    }
  };

  useEffect(() => {
    // Add event listener to handle keyboard events
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [focusedIndex, filteredOptions]);

  return (
    <Box>
      {/* <Typography className={classes.headers}>Suggest ideas</Typography> */}
      <List>
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, index) => (
            <ListItem
              onClick={option.handleButtonClick}
              key={option.text}
              className={`${classes.listItemContainer} ${
                index === focusedIndex && option.enabled
                  ? classes.focusedItem
                  : ''
              }`}>
              <ListItemIcon
                sx={{
                  minWidth: 40,
                  color: option.enabled ? 'inherit' : '#8A8A8A',
                }}>
                {option.icon}
              </ListItemIcon>
              <ListItemText
                primary={option.text}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 300,
                    cursor: 'pointer',
                    color: option.enabled ? 'inherit' : '#8A8A8A',
                    '&:hover': {
                      color: option.enabled ? 'inherit' : '#8A8A8A',
                    },
                  },
                }}
              />
              {option.shortcut !== '' && (
                <Box
                  sx={{
                    border: '1px solid #D9D9D9',
                    borderRadius: '4px',
                    width: option.enabled ? '18px' : '80px',
                    height: '18px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Typography
                    variant="h6"
                    className={classes.listItemLetter}
                    sx={{
                      color: option.enabled ? 'inherit' : '#8A8A8A',
                    }}>
                    {option.shortcut}
                  </Typography>
                </Box>
              )}
            </ListItem>
          ))
        ) : (
          <Typography
            sx={{
              padding: '8px',
              color: '#000000',
              textAlign: 'center',
            }}>
            No results found
          </Typography>
        )}
      </List>
    </Box>
  );
}

export default SuggestIdeas;
