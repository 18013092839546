import { useEffect, useState } from 'react';
import { onValue, ref, set } from 'firebase/database';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, SnackbarContent, Stack, Typography } from '@mui/material';

import { database } from '../firebase/FirebaseIndex';
import { getUserForwardedFiles } from '../redux/slices/Auth';
import actions from '../redux/actions';
import { importModelViews } from '../redux/slices/View';
import { EVENTS, phTrackEvent } from '../analytics';

function UserAlerts() {
  const firebaseUser = useSelector((state) => state.Auth.firebaseUser);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [showReviewButton, setShowReviewButton] = useState(false);
  const tripLoaded = useSelector((state) => state.Trips.status) === 'SUCCESS';
  const dispatch = useDispatch();

  useEffect(() => {
    if (firebaseUser?.uid && tripLoaded) {
      const listenToNotificationsUnsub = onValue(
        ref(database, `user_alerts/${firebaseUser.uid}/meta`),
        (snapshot) => {
          if (!snapshot.val()) return;
          const { newNotification } = snapshot.val();
          if (!newNotification) return;
          onValue(
            ref(
              database,
              `user_alerts/${firebaseUser.uid}/alerts/${newNotification}`
            ),
            (notificationSnapshot) => {
              if (!notificationSnapshot.exists()) return;
              if (
                notificationSnapshot.val()?.type === 'emailImportInitAlert' ||
                notificationSnapshot.val()?.type === 'importProcessedAlert'
              ) {
                phTrackEvent({
                  event: EVENTS.SMART_IMPORT.TRIP_EMAIL_SUBMIT,
                });
                setShowReviewButton(true);
                dispatch(
                  actions.View.setShowUnreadForwardedFilesNotificationDot(true)
                );
                dispatch(
                  actions.View.setImportIdToReviewIfNotSet(
                    notificationSnapshot.val()?.meta?.importId
                  )
                );
                try {
                  dispatch(
                    getUserForwardedFiles({
                      variables: {
                        id: firebaseUser.uid,
                      },
                    })
                  ).catch((err) => {
                    console.error(err);
                  });
                } catch (e) {
                  console.error(e);
                }
              }
              setSnackbarMessage(
                notificationSnapshot.val()?.body ||
                  "We've detected details on one of your files"
              );
              setIsSnackbarOpen(true);
              // set it null so it doesn't trigger again
              set(ref(database, `user_alerts/${firebaseUser.uid}/meta`), {
                newNotification: null,
              });
            },
            {
              onlyOnce: true,
            }
          );
        }
      );
      return listenToNotificationsUnsub;
    }
  }, [firebaseUser, tripLoaded]);

  return (
    <Snackbar
      open={isSnackbarOpen}
      autoHideDuration={4000}
      sx={{
        marginBottom: 10,
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={() => setIsSnackbarOpen(false)}>
      <SnackbarContent
        sx={{
          backgroundColor: '#FFDF97',
          color: '#4E4E4E',
        }}
        message={
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            alignItems="center">
            <Typography>{snackbarMessage}</Typography>
            {showReviewButton && (
              <Typography
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  phTrackEvent({
                    event: EVENTS.SMART_IMPORT.TRIP_IMPORT_NOTIFICATION_CLICK,
                  });
                  setIsSnackbarOpen(false);
                  dispatch(actions.View.setIsFilesTabOpen(true));
                  dispatch(
                    actions.View.setImportsModelView(importModelViews.review)
                  );
                }}>
                Review
              </Typography>
            )}
          </Stack>
        }
      />
    </Snackbar>
  );
}

export default UserAlerts;
