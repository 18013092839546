/* eslint-disable */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  useMediaQuery,
  ClickAwayListener,
  CircularProgress,
  ButtonGroup,
  Button,
  Popper,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import {
  StarRounded,
  ChevronRightRounded,
  CheckRounded,
  AddRounded,
  ArrowDropDownRounded,
  Close,
} from '@mui/icons-material';
import { makeStyles, useTheme } from '@mui/styles';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { format } from 'date-fns';
import { addSavedItem, deleteSavedItem } from '../../../redux/slices/Bookings';
import classList from '../../classList';
import actions from '../../../redux/actions';
import HotelPricesPopper from './HotelPricesPopper';
import Carousel from '../../molecules/Carousel';
import { useSounds, SOUNDS } from '../../../sounds';
import { EVENTS, phTrackEvent } from '../../../analytics';
import { Bookmark } from '../../atoms/Icon';
import TripSelectorStays from './TripSelectorStays';
import {
  createAccommodation,
  deleteAccommodation,
} from '../../../redux/slices/Accommodation';
import { firebaseAuth } from '../../../provider/AuthProvider';
import { useMapUtils } from '../MapUtils';
import { createLocation, deleteLocation } from '../../../redux/slices/Location';
import NotificationPopper from './NotificationPopper';
import { debounce, getTextWidth, parseISODate } from '../../../utils';
import guestToString, {
  guestToStringV2,
  ONE_DAY_MS,
  stringToGuestV2,
} from './bookingsUtils';
import FLAGS from '../../../featureFlags';
import { getFormattedNightlyPrice } from './HotelDetails/utils';
import { SecondaryOutlinedButton } from '../../atoms/Button/index';

const useStyles = makeStyles(({ palette }) => ({
  saveIconContainer: ({ saved }) => ({
    padding: 2,
    backgroundColor: 'transparent',
    '& > svg > path': {
      stroke: saved ? '#ED702E' : '#D9D9D9',
      strokeWidth: '1.5',
    },
    '&:hover': {
      '& > svg > path': {
        stroke: '#FFA766',
      },
    },
  }),
  cardContent: ({ isPopupCard }) => ({
    padding: isPopupCard ? '6px 12px 12px 12px !important' : '2px 0px',
    cursor: 'pointer',
  }),
  actionIconBtn: {
    position: 'absolute',
    width: '30px',
    height: '30px',
    top: 10,
    right: 4,
    zIndex: 3,
    backgroundColor: '#FFF',
    padding: 8,
    borderRadius: '50%',
    display: 'flex',
    color: palette.text.light,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  saveIconContainerN: {
    position: 'absolute',
    width: '30px',
    height: '30px',
    top: 10,
    right: 4,
    zIndex: 3,
    backgroundColor: '#FFF',
    padding: 8,
    borderRadius: '50%',
    display: 'flex',
    color: palette.text.light,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFF',
      '& > svg': {
        color: palette.primary.main,
      },
    },
  },
  addToTripIconContainer: {
    position: 'absolute',
    width: '30px',
    height: '30px',
    top: 10,
    right: 40,
    zIndex: 3,
    backgroundColor: '#FFF',
    padding: 8,
    borderRadius: '50%',
    display: 'flex',
    color: palette.text.light,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFF',
      '& > svg': {
        color: palette.primary.main,
      },
    },
    '&.Mui-disabled': {
      backgroundColor: '#FFF',
      color: palette.text.light,
      '&:hover': {
        backgroundColor: '#FFF',
        '& > svg': {
          color: palette.text.light,
        },
      },
    },
  },
  popper: {
    zIndex: 9500,
    width: 'inherit',
  },
  list: {
    maxHeight: 160,
    overflowY: 'auto',
    backgroundColor: '#FFF',
    border: '2px solid #DEDDDD',
    borderRadius: 4,
    padding: '4px 0px',
    marginTop: 8,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: 12,
    },
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
  },
  listItem: {
    minWidth: 120,
    padding: '4px 12px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      '& .MuiListItemIcon-root': {
        color: '#ED702E !important',
      },
    },
  },
  listItemText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  savedIcon: ({ saved }) => ({
    color: saved ? palette.primary.main : 'currentColor',
    fill: saved ? palette.primary.main : 'none',
  }),
}));

function HotelDetailsCard({
  index,
  hotelKey = '',
  hotelName = '',
  starRating = '0',
  guestRating = 0,
  numberOfReviews = 0,
  guestRatingSentiment = 'Not Available',
  rates = [],
  providers = [],
  currencyCode = '',
  images,
  referenceId = '',
  coordinates = {},
  isSavedItem = false,
  city = '',
  clickable = true,
  cardRef,
  numberOfNights = 1,
  isPopupCard = false,
  propertyType = 'Hotel',
  locationsList,
  accomodationKeyId,
  latlong,
  disableProviders = false,
  forwardUrlParams = {},
}) {
  const dispatch = useDispatch();
  const isPilotBookings = useFeatureFlagEnabled(FLAGS.PILOT_BOOKINGS);

  const { slug: tripId } = useParams();
  const { playSound } = useSounds();

  const { user } = useContext(firebaseAuth);
  const cardTitleContainerRef = useRef(null);
  const dropdownRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const deviceSupportsHover = useMediaQuery('(hover:hover)');

  const currencyFormatter = new Intl.NumberFormat(undefined, {
    currency: currencyCode || 'USD',
    style: 'currency',
    maximumFractionDigits: 0,
  });

  const [cardHovered, setCardHovered] = useState(false);
  const [providerDealsAnchor, setProviderDealsAnchor] = useState(null);
  const [isTripSelectorOpen, setIsTripSelectorOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [activeActionProps, setActiveActionProps] = useState(null);
  const [addToTripLoader, setAddToTripLoader] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const popperId = open ? 'search-location-popper' : undefined;

  const popperRef = useRef(null);
  const {
    cityLocationDetails,
    search: { date = null, guestCount = null },
    hotelList = [],
    hoveredHotelMarker,
  } = useSelector((state) => state.Bookings[tripId] || {});
  const mapPins = useSelector((state) => state.Map.mapPins[tripId] || {});
  const tripItems = useSelector((state) => state.Trips.trips[tripId].items);
  const locationIds = tripItems
    ?.filter((item) => item.location !== null)
    ?.map((item) => item.location);
  const locations = useSelector((state) => state.Location.locations);
  const locationContext = useSelector(
    (state) => state.Bookings[tripId]?.cityLocationDetails || {}
  );

  // State to indicate whether item is saved
  const savedItemList = useSelector((state) =>
    Object.values(state.Bookings.saved[tripId] || {})?.filter(
      (saved) => saved?.referenceId === referenceId
    )
  );

  const savedItem = savedItemList?.length > 0 && savedItemList[0];
  const isSaved = Boolean(savedItem);

  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lowestRate, setLowestRate] = useState(null);

  const classes = useStyles({ saved, isPopupCard });

  const { createMapPinForPlace, createMapPinForPlaceId } = useMapUtils();

  useEffect(() => {
    if (rates?.length) {
      setLowestRate([...rates].sort((a, b) => a.totalRate - b.totalRate)[0]);
    }
  }, [rates]);

  useEffect(() => setSaved(isSaved), [isSaved]);

  const validLocations = useMemo(
    () =>
      Object.values(locations || {})
        .filter(
          (loc) => loc.tripID === tripId && loc.name !== '' && loc?.mapPin
        )
        .map((loc) => {
          const mapPin = mapPins[loc.mapPin] || null;
          if (!mapPin) return null;
          return {
            id: loc.id,
            title: loc.name,
            mapPin,
          };
        })
        .filter((tag) => Boolean(tag)),
    [locations, mapPins]
  );

  const similarLocations = useMemo(
    () =>
      validLocations?.filter(
        (location) =>
          location?.title?.toLowerCase()?.trim() ===
          locationContext?.title?.toLowerCase()?.trim()
      ),
    [locationContext, validLocations]
  );

  const handleAffiliateClick = (trackingProps = {}) => {
    phTrackEvent({
      event: EVENTS.EXPLORE_STAYS.STAY_DEAL_CLICK,
    });
    window?.heap?.track('stays_affiliate_click', {
      ...trackingProps,
      city,
      source: isSavedItem ? 'SAVED_LIST' : 'SEARCH',
    });
  };

  const handleSaveClick = async (e) => {
    e.stopPropagation();

    if (loading) return;

    setLoading(true);
    if (isSaved) {
      setSaved(false);
      playSound(SOUNDS.removeBaritone);
      await dispatch(
        deleteSavedItem({
          variables: {
            id: savedItem?.id,
          },
          tripId,
        })
      ).catch(() => setSaved(true));
    } else {
      setSaved(true);
      playSound(SOUNDS.heartChime);
      phTrackEvent({
        event: EVENTS.EXPLORE_STAYS.STAY_SAVED_LIST_ADD,
      });
      await dispatch(
        addSavedItem({
          variables: {
            type: 'HOTEL',
            tripId,
            referenceId,
            savedData: JSON.stringify({
              hotelKey,
              title: hotelName,
              coordinates,
              hotelName,
              starRating,
              guestRating,
              numberOfReviews,
              guestRatingSentiment,
              rates,
              images,
              referenceId,
              providers,
              currencyCode,
              propertyType,
            }),
          },
        })
      ).catch(() => setSaved(false));
    }
    setLoading(false);
  };

  const handleHotelMarkerSwitch = debounce((params) => {
    dispatch(actions.Bookings.setHoveredHotelMarker(params));
  }, 500);

  const handleCardMouseEnter = () => {
    setCardHovered(true);
    if (!isMobile && !isPopupCard) {
      if (isPilotBookings) {
        handleHotelMarkerSwitch({
          tripId,
          ...(index < hotelList?.length && {
            hoveredHotelMarker: {
              hotel: hotelList[index],
              shouldFocus: true,
              cardIndex: index,
            },
          }),
        });
      } else {
        dispatch(
          actions.Bookings.setHoveredCardIndex({ cardIndex: index, tripId })
        );
      }
    }
  };

  const handleMouseLeave = () => {
    setCardHovered(false);
    if (!isMobile && !isPopupCard) {
      if (isPilotBookings) {
        // handleHotelMarkerSwitch({ tripId });
      } else {
        dispatch(
          actions.Bookings.setHoveredCardIndex({ cardIndex: null, tripId })
        );
      }
    }
  };

  const handleMouseClick = () => {
    phTrackEvent({
      event: EVENTS.EXPLORE_STAYS.STAY_CLICK,
    });
    if (!isMobile && !isPopupCard) {
      dispatch(
        actions.Bookings.setClickedCardIndex({ cardIndex: index, tripId })
      );
    }
    if (hotelKey) {
      window.localStorage.setItem('prevTripId', tripId);
      window.localStorage.setItem(
        'returnUrl',
        `${window.location.pathname}${window.location.search}`
      );
      const formatGuest = isPilotBookings ? guestToStringV2 : guestToString;
      const urlParams = new URLSearchParams({
        checkInDate: format(
          date?.from || new Date(Date.now() + 4 * ONE_DAY_MS),
          'yyyy-MM-dd'
        ),
        checkOutDate: format(
          date?.to || new Date(Date.now() + 6 * ONE_DAY_MS),
          'yyyy-MM-dd'
        ),
        rooms: formatGuest(guestCount) || '2|0|1',
        city,
        ...forwardUrlParams,
      });

      window.open(
        `${window.location.origin}/hotel/${hotelKey}?${urlParams.toString()}`,
        '_blank'
      );
    }
  };

  const handleAddLocation = async () => {
    const placeDetails = {
      lat: cityLocationDetails?.latitude,
      long: cityLocationDetails?.longitude,
      placeId: cityLocationDetails?.placeId,
      title: cityLocationDetails?.title,
    };

    const mapPinId = await createMapPinForPlaceId(
      placeDetails?.placeId,
      'LOCATION'
    );
    const location = await dispatch(
      createLocation({
        variables: {
          name: placeDetails.title,
          tripID: tripId,
          mapPin: mapPinId,
          index: tripItems.length || 0,
        },
      })
    ).then((data) => data.payload.createLocation);

    return { ...location, mapPin: mapPinId };
  };

  const createMapPinForAccomodation = async () => {
    const mapPinDetails = {
      title: hotelName,
      photo: images[0]?.small,
      rating: starRating,
      website: '',
      ratingCount: numberOfReviews,
      lat: latlong?.latitude,
      long: latlong?.longitude,
      types: 'ACCOMMODATION',
      hotelId: hotelKey?.toString(),
    };
    const pinDetails = createMapPinForPlace(mapPinDetails, 'ACCOMMODATION');

    return pinDetails;
  };

  const [popperNotification, setPopperNotification] = useState(null);
  const popperAnchorRef = useRef(null);

  // Using useRef since it needs to persist across state updates
  const isNewDestination = useRef(false);

  const locationNameList = locationsList?.map((location) =>
    location.title.toLowerCase().trim()
  );

  const locationDetails = locationsList?.filter(
    (location) =>
      location.title.toLowerCase().trim() === city.toLowerCase().trim()
  );

  const getStayLocationInfo = (
    controlledSelectedLocation = selectedLocation
  ) => {
    try {
      if (controlledSelectedLocation) {
        // This location is selected form trip selector execute this
        return [
          controlledSelectedLocation?.locationId,
          controlledSelectedLocation?.hotels?.length || 0,
          controlledSelectedLocation?.title,
        ];
      }
      if (
        locationNameList?.includes(city?.toLowerCase().trim()) &&
        locationDetails?.length === 1
      ) {
        return [
          locationDetails[0]?.id,
          locationDetails[0]?.hotels?.length || 0,
          city,
        ];
      }
      return [null, 0, city];
    } catch (err) {
      return [null, 0, city];
    }
  };

  const handleUndoAction = async (undoActionProps = activeActionProps) => {
    if (!undoActionProps) return;

    const {
      locationId = null,
      locationMapPinId = null,
      hotelId = null,
      hotelMapPinId = null,
    } = undoActionProps;

    if (hotelId === null) return;

    if (isNewDestination.current) {
      await Promise.all([
        dispatch(
          deleteLocation({
            variables: {
              id: locationId,
              tripId,
            },
            mapPin: locationMapPinId,
          })
        ),
        dispatch(
          deleteAccommodation({
            variables: {
              id: hotelId,
              mapPin: hotelMapPinId,
              locationId,
            },
            extra: { locationId },
            mapPin: hotelMapPinId,
          })
        ),
      ]);
    } else {
      await dispatch(
        deleteAccommodation({
          variables: {
            id: hotelId,
            mapPin: hotelMapPinId,
            locationId,
          },
          extra: { locationId },
          mapPin: hotelMapPinId,
        })
      );
    }
    setActiveActionProps(null);
  };

  const handleAddAccommodationToTripClick = async (
    controlledSelectedLocation = selectedLocation
  ) => {
    setAddToTripLoader(true);
    try {
      const stayLocationInfo = getStayLocationInfo(controlledSelectedLocation);
      let [locationId] = stayLocationInfo;
      const [stayIndex, locationTitle] = stayLocationInfo.slice(1);
      let locationMapPinId = null;

      if (!locationId) {
        const location = await handleAddLocation();
        locationId = location?.id;
        locationMapPinId = location?.mapPin;
      }

      if (locationId) {
        const mapPinDetails = await createMapPinForAccomodation();
        const checkInDate = parseISODate(date?.from);
        const checkOutDate = parseISODate(date?.to);

        if (checkInDate) {
          // 10:00 AM
          checkInDate.setHours(16, 0, 0, 0);
        }

        if (checkOutDate) {
          // 4:00 PM
          checkOutDate.setHours(10, 0, 0, 0);
        }

        const hotel = (
          await dispatch(
            createAccommodation({
              variables: {
                user: [{ user: user.uid, option: '' }],
                type: 1,
                trip: tripId,
                index: stayIndex,
                name: hotelName,
                costPer: 1,
                cost: lowestRate?.totalRate,
                currency: currencyCode,
                city,
                checkInDate,
                checkOutDate,
                streetAddress: city,
                mapPin: mapPinDetails?.id,
                locationId,
              },
              extra: { locationId },
            })
          )
        )?.payload?.createAccommodation;
        isNewDestination.current = Boolean(locationMapPinId);
        // Set action props to handle undo action if needed
        setActiveActionProps({
          ...activeActionProps,
          locationId,
          locationMapPinId,
          hotelId: hotel?.id,
          hotelMapPinId: mapPinDetails?.id,
        });
      }
      setSelectedLocation(null);
      setPopperNotification(locationTitle);
    } catch (err) {
      // handle error
      setIsTripSelectorOpen(true);
    }
    setAddToTripLoader(false);
  };

  const isStayAddedToTrip = accomodationKeyId?.includes(hotelKey) || false;

  const handleAddToTripButtonClick = async (e) => {
    e.stopPropagation();

    // if duplicate location exist then open the tripSelector else open notification popup
    if (locationDetails?.length > 1 || isSavedItem) {
      setIsTripSelectorOpen(true);
    } else {
      await handleAddAccommodationToTripClick();
    }
  };

  const handleCloseClick = () => {
    dispatch(
      actions.Bookings.setActiveHotelMarker({
        tripId,
        activeHotelMarker: {},
      })
    );
    dispatch(
      actions.Bookings.setHoveredHotelMarker({
        tripId,
        hoveredHotelMarker: {},
      })
    );
  };

  const handleAddToTripClick = (e) => {
    e.stopPropagation();
    setAnchorEl(dropdownRef?.current);
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
    // setLocationPopperOpen(null);
    // handleClose(null);
  };

  const renderLocations = () =>
    locationIds &&
    locationIds.map((locationId) => {
      const location = locations[locationId];
      if (!location) return;
      const { id, name } = location;
      // eslint-disable-next-line consistent-return
      return (
        <ListItem
          key={id}
          onClick={async () => {
            await handleAddAccommodationToTripClick({
              locationId: id,
              title: name,
              hotels: [],
            });
            handleClickAway();
          }}
          id="add-to-trip-button"
          className={classes.listItem}
          style={{
            paddingLeft: 14,
          }}
          alignItems="center">
          <ListItemText
            primary={name}
            primaryTypographyProps={{ fontSize: '14px' }}
            className={classes.listItemText}
          />
        </ListItem>
      );
    });

  return (
    <Box ref={popperRef}>
      {isTripSelectorOpen ? (
        <TripSelectorStays
          setIsTripSelectorOpen={setIsTripSelectorOpen}
          handleLocationUpdate={async (locationProps) => {
            if (locationProps?.locationId === activeActionProps?.locationId)
              return;
            setSelectedLocation(locationProps);
            await handleUndoAction();
            await handleAddAccommodationToTripClick(locationProps);
          }}
          defaultLocationId={getStayLocationInfo()[0] || null}
          locationsList={locationsList}
        />
      ) : (
        <Card
          ref={cardRef}
          sx={{
            boxShadow: isPopupCard ? 'auto' : 'none',
            cursor: 'pointer',
          }}
          onClick={clickable ? handleMouseClick : null}
          onMouseEnter={handleCardMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <div>
            <NotificationPopper
              open={Boolean(popperNotification)}
              anchorEl={popperRef?.current}
              onClose={(e, reason) => {
                if (reason === 'timeout') {
                  setActiveActionProps(null);
                }
                setPopperNotification(null);
              }}
              onCancel={async () => {
                setPopperNotification(null);
                if (locationsList?.length > 1) {
                  setIsTripSelectorOpen(true);
                } else {
                  setAddToTripLoader(true);
                  await handleUndoAction();
                  setAddToTripLoader(false);
                }
              }}
              destinationName={popperNotification}
              isNewDestination={isNewDestination?.current}
              cancelButtonLabel={locationsList?.length <= 1 ? 'Undo' : 'Change'}
            />
            <Box ref={popperAnchorRef} sx={{ position: 'relative' }}>
              {!isPopupCard && (
                <>
                  <IconButton
                    disabled={isStayAddedToTrip || addToTripLoader}
                    onClick={handleAddToTripButtonClick}
                    className={`${classes.addToTripIconContainer}`}
                    id={`hotel-card-add-to-trip-button-${index}`}>
                    {addToTripLoader ? (
                      <CircularProgress size={14} />
                    ) : isStayAddedToTrip ? (
                      <CheckRounded
                        fontSize="small"
                        sx={{ color: '#43A047' }}
                      />
                    ) : (
                      <AddRounded fontSize="small" />
                    )}
                  </IconButton>
                  <IconButton
                    onClick={handleSaveClick}
                    className={`${
                      saved
                        ? classList.staysRemoveFromSavedListBtn
                        : classList.staysAddToSavedListBtn
                    } ${classes.saveIconContainerN}`}
                    id={`hotel-details-card-save-button-${index}`}>
                    <Bookmark className={classes.savedIcon} />
                  </IconButton>
                </>
              )}

              {cardHovered && isPopupCard && (
                <IconButton
                  onClick={handleCloseClick}
                  className={classes.actionIconBtn}>
                  <Close
                    height={16}
                    width={16}
                    sx={{ fontSize: '14px', color: '#000000' }}
                  />
                </IconButton>
              )}
              <Carousel showArrows={cardHovered} images={images} />
            </Box>

            <CardContent className={classes.cardContent}>
              <Box>
                <div
                  ref={cardTitleContainerRef}
                  style={{
                    minHeight: 40,
                    height: 40,
                    overflow: 'hidden',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '4px',
                    }}>
                    <Typography
                      variant="h5"
                      fontWeight="700"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                      {hotelName}{' '}
                      <Typography
                        variant="h5"
                        fontSize="12px"
                        color="text.secondary"
                        sx={
                          getTextWidth(hotelName, '14px Inter') >
                          (cardTitleContainerRef?.current?.clientWidth || 0)
                            ? {
                                display: 'inline-flex',
                                marginLeft: '2px',
                              }
                            : {
                                marginTop: '-2px',
                              }
                        }>
                        {propertyType}
                      </Typography>
                    </Typography>
                    {isPopupCard && (
                      <IconButton
                        sx={{
                          borderRadius: '8px',
                          width: '30px',
                          height: '30px',
                        }}
                        onClick={handleSaveClick}>
                        <Bookmark
                          height={16}
                          width={16}
                          className={classes.savedIcon}
                        />
                      </IconButton>
                    )}
                  </Box>
                </div>
                <div style={{ marginTop: 6 }}>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="flex-start">
                    <Box display="flex" flexWrap="wrap" flex="0">
                      <Box
                        display="flex"
                        flexWrap="nowrap"
                        marginRight="6px"
                        marginBottom="-4px">
                        <Typography variant="h3">
                          {currencyFormatter.format(
                            getFormattedNightlyPrice(
                              lowestRate?.totalRate,
                              numberOfNights || 1
                            )
                          )}
                        </Typography>
                        <Typography
                          variant="h5"
                          fontSize="12px"
                          color="text.secondary"
                          // computed lineHeight based on above h3
                          lineHeight="22.4px"
                          noWrap>
                          &nbsp;/ night
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="flex-start">
                        <Typography
                          variant="h5"
                          fontSize="12px"
                          lineHeight="22.4px"
                          color="text.secondary">
                          {`(${currencyFormatter.format(
                            getFormattedNightlyPrice(
                              lowestRate?.totalRate,
                              numberOfNights || 1
                            ) * (numberOfNights || 1)
                          )} total)`}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      alignItems="flex-start">
                      {(rates || [])?.length > 0 && !disableProviders && (
                        <ClickAwayListener
                          onClickAway={() =>
                            !deviceSupportsHover && setProviderDealsAnchor(null)
                          }>
                          <Box
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!deviceSupportsHover)
                                setProviderDealsAnchor(e?.currentTarget);
                            }}
                            onMouseEnter={(e) =>
                              setProviderDealsAnchor(e?.currentTarget)
                            }
                            onMouseLeave={() => setProviderDealsAnchor(false)}
                            sx={{
                              cursor: 'pointer',
                              flex: 1,
                            }}>
                            <Typography
                              variant="h5"
                              color="text.secondary"
                              lineHeight="22.4px"
                              sx={{
                                px: '2px',
                                backgroundColor: providerDealsAnchor
                                  ? '#F4F4F4'
                                  : 'transparent',
                              }}>
                              {rates?.length} deals offered
                              <ChevronRightRounded
                                sx={{
                                  transform: 'rotate(90deg)',
                                  height: 16,
                                  width: 16,
                                  mb: '-4px',
                                }}
                              />
                            </Typography>
                            <HotelPricesPopper
                              rates={rates}
                              providers={providers}
                              anchorEl={providerDealsAnchor}
                              currencyCode={currencyCode}
                              numberOfNights={numberOfNights}
                              handleAffiliateClick={handleAffiliateClick}
                            />
                          </Box>
                        </ClickAwayListener>
                      )}
                    </Box>
                  </Box>
                </div>
                {guestRating > 0 && (
                  <Box display="flex" justifyContent="space-between">
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      mt="6px">
                      <StarRounded
                        sx={{
                          color: '#ED702E',
                          height: 20,
                          width: 20,
                        }}
                      />
                      <Typography variant="h5" color="text.secondary" ml="2px">
                        <b>{guestRating}</b> {`(${numberOfReviews})`}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
              <div
                style={{
                  display: 'flex',
                  paddingTop: '6px',
                  marginTop: 'auto',
                  justifyContent: 'center',
                }}>
                {isPopupCard && (
                  <div
                    style={{
                      display: 'flex',
                      gap: '8px',
                      justifyContent: 'space-between',
                    }}>
                    <ButtonGroup
                      variant="outlined"
                      sx={{
                        boxShadow: 'none',
                        '& .MuiButtonGroup-grouped': {
                          minWidth: 0,
                        },
                      }}>
                      <SecondaryOutlinedButton
                        sx={{
                          padding: '2px 12px',
                          fontSize: '12px',
                          borderRadius: 1,
                          borderRight: 'none',
                          '&:hover': {
                            borderColor: '#D9D9D9',
                            borderRight: 'none',
                            backgroundColor: '#F4F4F4',
                            color: '#000',
                            transition: 'none',
                          },
                        }}
                        onClick={async () => {
                          const location =
                            similarLocations?.length > 0
                              ? similarLocations[0]
                              : null;
                          await handleAddAccommodationToTripClick({
                            locationId: location?.id,
                            title: location?.title,
                            hotels: [],
                          });
                        }}>
                        Add to trip
                      </SecondaryOutlinedButton>
                      <SecondaryOutlinedButton
                        ref={dropdownRef}
                        sx={{
                          padding: '2px 2px',
                          borderRadius: 1,
                          borderLeft: 'none',
                          '&:hover': {
                            borderColor: '#D9D9D9',
                            backgroundColor: '#F4F4F4',
                            transition: 'none',
                            color: '#000',
                            borderLeft: 'none',
                          },
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            left: '0',
                            top: '30%',
                            height: '40%',
                            width: '1px',
                            backgroundColor: '#000',
                          },
                        }}
                        onClick={handleAddToTripClick}
                        size="small">
                        <ArrowDropDownRounded sx={{ padding: 0, margin: 0 }} />
                      </SecondaryOutlinedButton>
                    </ButtonGroup>
                    <Button
                      sx={{
                        padding: '2px 28px',
                        fontSize: '12px',
                        '&:hover': {
                          backgroundColor: '#D35E07',
                        },
                      }}
                      onClick={handleMouseClick}>
                      Book now
                    </Button>
                  </div>
                )}
              </div>
              <Popper
                id={popperId}
                open={open}
                anchorEl={anchorEl}
                className={classes.popper}>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <List className={classes.list}>{renderLocations()}</List>
                </ClickAwayListener>
              </Popper>
            </CardContent>
          </div>
        </Card>
      )}
    </Box>
  );
}

export default HotelDetailsCard;
