import React, { useState } from 'react';
import {
  Button,
  Popover,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  styled,
  ListItemIcon,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFileIcon } from '../../../../utils';

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  padding: '8px 14px',
  color: theme.palette.text.secondary,
  backgroundColor: 'white',
  textTransform: 'none',
  marginTop: '8px',
  border: '1px solid #E5E7EB',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#F9FAFB',
    color: 'black',
    borderColor: '#D1D5DB',
  },
  '& .MuiButton-endIcon': {
    marginLeft: 'auto',
  },
}));

const StyledPopover = styled(Popover)(() => ({
  '& .MuiPaper-root': {
    marginTop: '8px',
    boxShadow:
      '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
    borderRadius: '6px',
  },
}));

export function FilesSelect({
  onFileSelect,
  placeholder = 'Select file...',
  selectDisabled,
  filter = () => true,
  onRef,
}) {
  const tripId = useParams().slug;
  const [selectedFile, setSelectedFile] = useState(null);
  const files = useSelector(
    (state) =>
      Object.values(state.Files.files).filter(
        (file) => file?.tripId === tripId
      ),
    shallowEqual
  ).filter(filter);

  const [anchorEl, setAnchorEl] = useState(null);
  const [width, setWidth] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setWidth(event.currentTarget.clientWidth + 32);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option) => {
    setSelectedFile(option);
    onFileSelect?.(option);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const renderIcon = (extension) => {
    const LogoIcon = getFileIcon(extension);
    return <LogoIcon />;
  };
  return (
    <Box width="100%">
      <StyledButton
        ref={onRef}
        disabled={selectDisabled}
        onClick={handleClick}
        endIcon={
          <KeyboardArrowDownIcon
            sx={{ fontSize: 20, color: 'text.secondary' }}
          />
        }
        aria-haspopup="listbox"
        aria-expanded={open}
        aria-label="select file">
        {selectedFile?.name &&
          renderIcon(selectedFile?.name?.split('.')?.pop())}

        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: 400,
            textAlign: 'left',
          }}>
          {selectedFile
            ? files.find((file) => file.id === selectedFile?.id)?.name
            : placeholder}
        </Typography>
      </StyledButton>
      <StyledPopover
        sx={{
          width,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <List sx={{ py: 0.5, width }}>
          {files.map((file) => (
            <ListItem
              key={file.id}
              onClick={() => {
                handleSelect(file);
              }}
              sx={{
                py: 1,
                px: 2,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
              }}>
              <ListItemIcon>
                {renderIcon(file?.name?.split('.')?.pop())}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: '0.875rem' }}>
                    {file.name}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </StyledPopover>
    </Box>
  );
}

export default FilesSelect;
