import { makeStyles } from '@mui/styles';
import {
  Stack,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addUserToTrip, getTrips } from '../../../../redux/slices/Trips';
import classList from '../../../classList';
import { EVENTS, phTrackEvent } from '../../../../analytics';

const useStyles = makeStyles(({ palette }) => ({
  declineButton: {
    width: '50%',
    display: 'inline-block',
    backgroundColor: 'transparent',
    color: palette.primary?.light,
    borderColor: palette?.primary?.light,
    borderStyle: 'solid',
    borderWidth: '1px',
    '&:hover': {
      borderColor: palette?.primary?.main,
      backgroundColor: 'transparent',
      // backgroundColor: palette?.primary?.main,
      color: palette?.primary?.main,
    },
  },
}));

function FooterActions({ tripId, sharedUsers }) {
  const styles = useStyles();
  const { palette, breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const user = useSelector((state) => state.Auth?.userData?.user);
  const firebaseUser = useSelector((state) => state.Auth.firebaseUser);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isJoined = sharedUsers?.some(
    (_user) => _user?.id === firebaseUser?.uid
  );

  const handleJoinTrip = async () => {
    if (isJoined) {
      return;
    }
    phTrackEvent({
      event: EVENTS.TRIP_INVITE.INVITE_START,
      meta: {
        tripId,
      },
    });
    const res = await dispatch(
      addUserToTrip({
        variables: {
          tripId,
          userId: user?.id,
        },
      })
    );
    if (res?.payload?.addUserToTrip?.userAddedToTrip) {
      dispatch(getTrips(firebaseUser.uid));
      // setTimeout(() => {
      navigate(`/trips/${tripId}/planner`);
      // }, 4000);
    }
  };

  const handleDeclineTrip = async () => {
    phTrackEvent({
      event: EVENTS.TRIP_INVITE.INVITE_LATER_CLICK,
      meta: {
        tripId,
      },
    });
    navigate('/trips');
  };

  return (
    <>
      <Stack width="100%" flexDirection="row" justifyContent="center">
        <Stack
          px={isMobile ? '10px' : '0px'}
          flexDirection="row"
          width={isMobile ? '100%' : '430px'}
          gap="12px"
          alignItems="center"
          justifyContent="center">
          <Button
            onClick={handleDeclineTrip}
            className={`${styles.declineButton}`}>
            Maybe later
          </Button>{' '}
          <Tooltip
            title={isJoined ? 'You are already a part of this trip' : ''}
            placement="bottom"
            arrow>
            <Button
              onClick={handleJoinTrip}
              sx={{ width: '50%', opacity: isJoined ? 0.9 : 1 }}
              className={classList.joinTripButton}>
              Join the trip
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
      <Typography
        sx={{ marginTop: '20px' }}
        textAlign="center"
        width={isMobile ? '200px' : '100%'}
        fontSize="10px"
        fontWeight={500}
        mt="4px"
        color={palette?.text?.light}>
        Don&apos;t worry, you can revisit the invitation by using the same link!{' '}
      </Typography>
    </>
  );
}

export default FooterActions;
