import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import {
  Grid,
  InputBase,
  IconButton,
  Typography,
  useMediaQuery,
  Stack,
  Box,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { SearchRounded, CloseRounded } from '@mui/icons-material';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { OutlinedButton } from '../../atoms/Button/index';
import { handleFileDelete } from '../../../utils';
import { UploadIcon } from '../../atoms/Icon';
import { updateTrip } from '../../../redux/slices/Trips';

import { FileUploadModal, FilePreviewModal } from '../../molecules/Modal';
import BookingList from '../Checkout/BookingList';
import FileRowV1 from './FileRowV1';
import FileRowV2 from './FileRowV2';
import { FileUpload } from '../../molecules/Modal/FileUploadModal';
import FLAGS from '../../../featureFlags';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  // searchbar
  searchBar: {
    maxWidth: 240,
    backgroundColor: '#F4F4F4',
    borderRadius: 6,
    padding: 7,
    marginRight: 10,
    border: '1px solid transparent',
    '&:hover': {
      boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    },
  },
  searchbarFocused: {
    backgroundColor: '#FFF',
    boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    border: `1px solid ${palette.primary.light}`,
  },
  closeButton: {
    color: '#000',
    padding: 2,
    marginRight: 4,
    marginLeft: 4,
    '&:hover': {
      cursor: 'pointer',
    },
  },

  // files tab
  root: {
    marginTop: '40px',
    padding: '0px 14vw',
    [breakpoints.down('lg')]: {
      padding: '0px 10vw',
    },
    [breakpoints.down('md')]: {
      padding: '0px 5vw',
    },
    [breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  container: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      padding: '0 10px',
    },
  },
}));

function FileSearchbar({ handleSubmit = () => {} }) {
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  return (
    <InputBase
      disableUnderline
      className={{
        [classes.searchBar]: true,
        [classes.searchbarFocused]: isFocused,
      }}
      placeholder="Search for files"
      value={searchString}
      onChange={(e) => {
        setSearchString(e.target.value);
        handleSubmit(e.target.value);
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') handleSubmit(searchString);
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      inputProps={{
        style: {
          padding: 0,
        },
      }}
      startAdornment={
        <SearchRounded
          style={{
            color: '#8A8A8A',
            width: 16,
            height: 16,
            marginRight: 8,
            marginLeft: 4,
          }}
        />
      }
      endAdornment={
        searchString !== '' && isFocused ? (
          <IconButton
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              setSearchString('');
              handleSubmit('');
            }}
            className={classes.closeButton}
            size="large">
            <CloseRounded
              style={{
                color: '#000',
                width: 14,
                height: 14,
              }}
            />
          </IconButton>
        ) : null
      }
    />
  );
}

const filesListStyles = makeStyles(() => ({
  headerText: {
    fontSize: 12,
    color: '#4E4E4E',
    textTransform: 'uppercase',
    padding: '0 4px',
  },
  mainText: {
    fontSize: 14,
    color: '#000',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  secondaryText: {
    fontSize: 14,
    color: '#8A8A8A',
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  headerContainer: {
    borderBottom: '1px solid #DEDDDD',
    padding: '6px 12px',
  },
  nameRow: {
    cursor: 'pointer',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  nameRowFocused: {
    background: '#FFFFFF',
    boxShadow: 'inset 0px 0px 0px 2px #F4F4F4',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  filesListContainer: {
    // 100vh - (static space above the files list + navbar height + static padding below the files list).
    maxHeight: 'calc(100vh - 234px - 60px)',
    overflowY: 'auto',
  },
  fileContainer: {
    borderBottom: '1px solid #DEDDDD',
    padding: '4px 0px',
  },
  fileWrapper: {
    borderRadius: 4,
    padding: '0 12px',
  },
  flexRow: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    padding: '8px 4px',
  },
  moreVertButton: {
    padding: 2,
  },
  moreVertIcon: {
    width: 16,
    height: 16,
  },
  fileLogo: {
    marginRight: 9,
    height: 20,
    width: 20,
    viewBox: '0 0 20 20',
    minWidth: 20,
  },
  fileAttachTrigger: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    padding: '0 4px',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  renameInput: {
    padding: 0,
    fontSize: 14,
  },
}));

function FilesList({ files, handleTriggerDeleteFile, tripId, tripFiles }) {
  const classes = filesListStyles();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const isSmallDevice = useMediaQuery('(max-width:660px)');
  const isSmartImportEnabled = useFeatureFlagEnabled(FLAGS.SMART_IMPORT);
  // const isSmartImportEnabled = false;

  const showDetectedDetails = isSmartImportEnabled && !isSmallDevice;
  const FileRow = isSmartImportEnabled ? FileRowV2 : FileRowV1;
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: 26, flexWrap: 'nowrap' }}>
      <Grid item container className={classes.headerContainer}>
        <Grid item xs={isSmallDevice ? 5 : showDetectedDetails ? 4 : 6}>
          <Typography className={classes.headerText} ml={5}>
            File Name
          </Typography>
        </Grid>
        {showDetectedDetails && (
          <Grid item xs={4} display="flex" justifyContent="center">
            <Typography
              className={classes.headerText}
              sx={{
                marginLeft: 0,
              }}
              ml={5}>
              Detected Details
            </Typography>
          </Grid>
        )}
        {/* <Grid item xs={isSmallDevice ? 4 : showDetectedDetails ? 2 : 3}> */}
        <Grid item xs={isSmartImportEnabled && isSmallDevice ? 4 : 2}>
          <Typography className={classes.headerText}>Attached To</Typography>
        </Grid>
        <Grid
          alignItems="center"
          display="flex"
          justifyContent="center"
          item
          sx={{
            paddingRight: isSmartImportEnabled ? 0 : isSmallDevice ? 0 : 8,
            paddingLeft: isSmartImportEnabled ? 0 : isSmallDevice ? 8 : 0,
          }}
          xs={isSmallDevice ? 3 : showDetectedDetails ? 2 : 3}>
          <Typography className={classes.headerText}>Uploaded by</Typography>
        </Grid>
      </Grid>
      <Grid item container className={classes.filesListContainer}>
        {files?.map((file) => (
          <FileRow
            isForwardedFile={file?.type === 'EmailAttachment'}
            isSelected={selectedFiles.includes(file.id)}
            onSelect={(checked) => {
              setSelectedFiles((prev) =>
                checked
                  ? [...prev, file.id]
                  : prev.filter((id) => id !== file.id)
              );
            }}
            file={file}
            key={file.id}
            handleTriggerDeleteFile={handleTriggerDeleteFile}
            tripId={tripId}
            tripFiles={tripFiles}
          />
        ))}
      </Grid>
    </Grid>
  );
}

function FilesTab({ trip }) {
  const classes = useStyles();
  const [filter, setFilter] = useState('');
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const dispatch = useDispatch();
  const isSmartImportEnabled = useFeatureFlagEnabled(FLAGS.SMART_IMPORT);

  const files = useSelector(
    (state) =>
      Object.values(state.Files.files).filter(
        (file) => file?.tripId === trip?.id
      ),
    shallowEqual
  );
  const { Transportation, Files } = useSelector((state) => state);

  const handleTriggerDeleteFile = (file) => {
    handleFileDelete(file, trip?.id, dispatch, Transportation, Files);
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Helmet>
        <title>
          {trip?.title ? `${trip?.title} - Files | Pilot` : 'Files | Pilot'}
        </title>
      </Helmet>
      <Grid
        item
        className={classes.container}
        sx={{ marginTop: { xs: '10px', sm: 0 } }}>
        <Grid item style={{ flex: 1 }}>
          <FileSearchbar
            handleSubmit={(searchFilter) => setFilter(searchFilter)}
          />
        </Grid>
        <Grid item>
          <OutlinedButton
            onClick={() => setOpenFileUploadModal(true)}
            startIcon={<UploadIcon />}>
            Upload
          </OutlinedButton>
        </Grid>
        <FileUploadModal
          open={openFileUploadModal}
          handleClose={() => setOpenFileUploadModal(false)}
          attachedToID={trip.id}
          attachedFiles={trip?.files}
          attachFunc={(newFiles) => {
            return new Promise((resolve, reject) => {
              dispatch(
                updateTrip({
                  variables: {
                    id: trip?.id,
                    files: newFiles,
                  },
                })
              )
                .then((res) => resolve(res))
                .catch((err) => reject(err));
            });
          }}
          filesTab
        />
        <FilePreviewModal fileUploadTrigger={setOpenFileUploadModal} />
      </Grid>
      <Grid item mt={2}>
        <BookingList />
      </Grid>
      <Grid item className={classes.container} style={{ maxWidth: '100vw' }}>
        {files?.length > 0 ? (
          <FilesList
            files={files?.filter((file) =>
              file?.name?.toLowerCase()?.includes(filter?.toLowerCase())
            )}
            handleTriggerDeleteFile={handleTriggerDeleteFile}
            tripId={trip?.id}
            tripFiles={trip?.files}
          />
        ) : (
          <FileUpload
            customHeader={
              isSmartImportEnabled ? (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  pb="55px"
                  sx={{
                    margin: {
                      xs: '0 16px',
                      sm: '0 124px',
                    },
                  }}>
                  <Typography fontSize="20px" fontWeight="500">
                    Upload Files to Get Started
                  </Typography>
                  <Typography color="#8A8A8A" fontSize="14px" fontWeight="400">
                    Pilot will automatically import any flights, bookings, and
                    reservations into your trip!
                  </Typography>
                </Stack>
              ) : (
                <span />
              )
            }
            customFooter={isSmartImportEnabled ? null : <Box width="100%" />}
            attachedToID={trip.id}
            attachedFiles={trip?.files}
            attachFunc={(newFiles) => {
              return new Promise((resolve, reject) => {
                dispatch(
                  updateTrip({
                    variables: {
                      id: trip?.id,
                      files: newFiles,
                    },
                  })
                )
                  .then((res) => resolve(res))
                  .catch((err) => reject(err));
              });
            }}
            filesTab
          />
        )}
      </Grid>
    </Grid>
  );
}

export default FilesTab;
