import React, { useState, useRef, useEffect } from 'react';
import {
  Popper,
  List,
  ListItem,
  ClickAwayListener,
  ListItemIcon,
  Divider,
  ListItemText,
  ButtonGroup,
} from '@mui/material';
import {
  ArrowDropDownRounded,
  AddCircleOutlineRounded,
  ChevronRightRounded,
  DeleteOutlineOutlined,
  LanguageOutlined,
} from '@mui/icons-material';

import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';

import { useMapUtils } from '../../organisms/MapUtils';
import { deleteActivity } from '../../../redux/slices/Activity';
import {
  LocationActions,
  createLocation,
  deleteLocation,
} from '../../../redux/slices/Location';
import { ButtonDefault } from '../../atoms/Button/index';
import { useSounds, SOUNDS } from '../../../sounds';
import { EVENTS, phTrackEvent } from '../../../analytics';
import { DeleteModal } from '../Modal';
import actions from '../../../redux/actions';
import useCityPlaceId from './useCityPlaceId';
import { updateSessionStorageForLastEditedSection } from '../../../utils';
import { useTour } from '../Tour/useTour';
import { stepName } from '../../../assets/onboarding/steps';

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 1121,
    width: 'inherit',
  },
  list: {
    maxHeight: 160,
    overflowY: 'auto',
    backgroundColor: '#FFF',
    border: '2px solid #DEDDDD',
    borderRadius: 4,
    padding: '4px 0px',
    marginTop: 8,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: 12,
    },
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
  },
  listItem: {
    minWidth: 120,
    padding: '4px 12px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      '& .MuiListItemIcon-root': {
        color: '#ED702E !important',
      },
    },
  },
  listItemText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  buttonContainer: {
    display: 'flex',
  },
  trashIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    opacity: 0,
    transition: 'visibility 0s, opacity 0.5s ease-in-out',
  },
  trashIcon: {
    cursor: 'pointer',
    color: 'grey',
    marginRight: 8,
  },
}));

function AddToTripButtonNew({
  tripId,
  placeDetails,
  // info we aren't storing in the backend
  additionalPlaceDetails = {},
  id: idSelector,
  className: classSelector,
  buttonProps = {},
  isAdded = false,
  customAnchor = null,
  CustomButton = null,
  setLoader = () => {},
  handleClose = () => {},
  trigger = null,
  hoverContentClass,
  activeCardDetails,
  handleAddActivity = () => {},
  setToastMessage = () => {},
  setShowSnackBar = () => {},
  setLastAddedActivity = () => {},
  setDestinationDelete = () => {},
  setActivityDelete = () => {},
  destinationDeletetimeoutRef,
  isCommandBarEnabled = false,
}) {
  const classes = useStyles({ isCommandBarEnabled });
  const [anchorEl, setAnchorEl] = useState(null);
  const { playSound } = useSounds();
  const dispatch = useDispatch();
  const currentTrip = useSelector((state) => state.Trips.trips[tripId]);
  const locationIds = currentTrip?.items
    ?.filter((item) => item.location !== null)
    ?.map((item) => item.location);
  const locations = useSelector((state) => state.Location.locations);
  const thingsToDo = useSelector((state) => state.Section.sections);

  const { getTour } = useTour();
  const ref = useRef(null);
  const hoverTimeRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [locationPopperOpen, setLocationPopperOpen] = useState(false);
  const [locationAnchor, setLocationAnchor] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [cityName, setCityName] = useState('');
  const commandBarState = useSelector((state) => state.View.commandBar);
  const { activeLocationId, activeSectionId } = commandBarState;

  const deletedDestinationIdRef = useRef(null);
  const deletedDestinationMapPinRef = useRef(null);

  const popperId = open ? 'search-location-popper' : undefined;

  const placeId = useCityPlaceId(cityName);

  const {
    createMapPinForPlace,
    extractAddressComponents,
    createMapPinForPlaceId,
  } = useMapUtils();

  const handleDropDownClick = (e) => {
    getTour().onActiveStep(stepName.ADD_TO_TRIP_BUTTON).closeTour();
    e.stopPropagation();
    setAnchorEl(CustomButton ? customAnchor : ref?.current);
    setOpen(!open);
  };

  const handleDropDownHover = (e) => {
    e.stopPropagation();
    clearTimeout(hoverTimeRef.current);
    if (!open) {
      setAnchorEl(ref?.current);
      setOpen(true);
    }
  };

  const handleDropDownLeave = (e) => {
    e.stopPropagation();
    // Adding delay to avoid flickering
    hoverTimeRef.current = setTimeout(() => {
      setAnchorEl(null);
      setOpen(false);
      setLocationPopperOpen(null);
    }, 300);
  };

  const deleteDestinationFromItinerary = async () => {
    if (
      deletedDestinationIdRef.current &&
      deletedDestinationMapPinRef.current
    ) {
      await dispatch(
        deleteLocation({
          variables: {
            id: deletedDestinationIdRef.current,
            tripId,
          },
          mapPin: deletedDestinationMapPinRef.current,
        })
      );
    }
  };

  const handleDeleteMapPin = async () => {
    if (activeCardDetails?.locationId) {
      // if adestinationDeletetimeoutRef already exits then immediately delete the previous destination
      if (destinationDeletetimeoutRef.current) {
        setShowSnackBar(false);
        await deleteDestinationFromItinerary();
        clearTimeout(destinationDeletetimeoutRef.current);
        destinationDeletetimeoutRef.current = null;
      }
      dispatch(
        LocationActions.removeLocationSectionTemp({
          locationId: activeCardDetails?.locationId,
        })
      );
      deletedDestinationIdRef.current = activeCardDetails?.locationId;
      deletedDestinationMapPinRef.current = activeCardDetails?.mapPin;
      destinationDeletetimeoutRef.current = setTimeout(() => {
        deleteDestinationFromItinerary();
      }, 5000);
      setDestinationDelete(true);
      setDeleteModalOpen(false);
    } else {
      await dispatch(
        deleteActivity({
          variables: {
            id: activeCardDetails?.toDoId,
            thingsToDoId: activeCardDetails?.sectionId,
          },
          mapPin: activeCardDetails?.mapPin,
          sectionId: activeCardDetails?.sectionId,
        })
      );
      setActivityDelete(true);
      // Update the sessionStorage for the lastUpdatedSectionDetails
      updateSessionStorageForLastEditedSection(
        activeCardDetails?.parentLocationId,
        activeCardDetails?.sectionId
      );
    }
    setLastAddedActivity({
      activityTitle: placeDetails?.title,
      locationId:
        activeCardDetails?.locationId || activeCardDetails?.parentLocationId,
      sectionId: activeCardDetails?.sectionId,
      sectionName: activeCardDetails?.sectionName,
      locationName:
        activeCardDetails?.locationName ||
        activeCardDetails?.parentLocationName,
    });
    if (activeCardDetails?.toDoName && activeCardDetails?.sectionName) {
      setToastMessage(
        `Deleted ${activeCardDetails?.toDoName} from ${activeCardDetails?.sectionName}`
      );
    } else {
      setToastMessage(`Deleted ${placeDetails?.title}`);
    }
    setShowSnackBar(true);
  };

  useEffect(() => {
    if (trigger && !anchorEl) {
      setAnchorEl(customAnchor);
      setOpen(true);
    }
  }, [trigger]);

  useEffect(() => {
    // clean up when component unmounts
    return () => {
      if (destinationDeletetimeoutRef.current) {
        deleteDestinationFromItinerary();
        clearTimeout(destinationDeletetimeoutRef.current);
        destinationDeletetimeoutRef.current = null;
      }
      if (hoverTimeRef.current) {
        clearTimeout(hoverTimeRef.current);
        hoverTimeRef.current = null;
      }
      dispatch(
        actions.View.setHighlightedSection({
          section: null,
        })
      );
      dispatch(
        actions.View.setHighlightedSection({
          section: null,
        })
      );
      setShowSnackBar(false);
      setToastMessage('');
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (placeId) {
        setLoader(true);
        //   once got the place id then create a new location under that.
        const mPin = await createMapPinForPlaceId(placeId);
        const res = await dispatch(
          createLocation({
            variables: {
              name: cityName,
              tripID: tripId,
              mapPin: mPin,
              index: 0,
            },
          })
        );
        if (res?.payload?.createLocation?.id) {
          handleAddActivity(
            placeDetails?.title,
            res.payload.createLocation.id,
            null,
            null,
            cityName,
            false
          );
          dispatch(
            actions.View.setCommandBar({
              activeLocationId: res?.payload?.createLocation?.id,
              activeSectionId: null,
            })
          );
        }
        setLoader(false);
      }
    })();
  }, [placeId]);

  const handleAddLocation = async () => {
    if (getTour().isActiveStep(stepName.ADD_TO_TRIP_BUTTON)) {
      getTour().closeTour();
    }
    setLoader(true);
    playSound(SOUNDS.inOutClick);
    const mapPinId = await createMapPinForPlace(
      placeDetails,
      'LOCATION',
      additionalPlaceDetails
    );
    const res = await dispatch(
      createLocation({
        variables: {
          name: placeDetails.title,
          tripID: tripId,
          mapPin: mapPinId,
          index: 0,
        },
      })
    );

    dispatch(
      actions.View.setCommandBar({
        activeLocationId: res?.payload?.createLocation?.id,
        activeSectionId: null,
      })
    );

    setLoader(false);
    if (getTour().isActiveStep(stepName.ADD_TO_TRIP_BUTTON)) {
      getTour().goToNextStep().openTour({ delay: 1000 });
    } else {
      getTour()
        .onActiveStep([
          stepName.FIRST_ACTIVITY_HOVER_INFO,
          stepName.ACTIVITY_ADDED_TO_TRIP_MOBILE,
        ])
        .openTour({ delay: 1000 });
    }
  };

  const handleClickAway = () => {
    setOpen(false);
    setLocationPopperOpen(null);
    handleClose(null);
  };

  const renderLocations = () =>
    locationIds &&
    locationIds.map((locationId) => {
      const location = locations[locationId];
      if (!location) return;
      const { id, name } = location;
      const handleLocationHover = (e) => {
        const element = document.getElementById(
          `scrollable-container-${locationId}`
        );
        if (element && !getTour().tourInProgress) {
          dispatch(
            actions.View.setHighlightedSection({
              section: locationId,
            })
          );
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
        if (locationPopperOpen === id) return;
        setLocationPopperOpen(id);
        setLocationAnchor(e.currentTarget);
      };
      const handleSectionHover = (e, highLightedsectionId) => {
        e.stopPropagation();
        dispatch(
          actions.View.setHighlightedSection({
            section: locationId,
          })
        );
        const element = document.getElementById(
          `todo-header-${highLightedsectionId}`
        );
        if (element && !getTour().tourInProgress) {
          dispatch(
            actions.View.setHighlightedHeading({
              section: highLightedsectionId,
            })
          );
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      };

      const sections =
        location?.thingsToDo?.map((thingsToDoId) => thingsToDo[thingsToDoId]) ||
        [];

      // eslint-disable-next-line consistent-return
      return (
        <ListItem
          key={id}
          onClick={() => {
            if (sections.length === 0) {
              handleAddActivity(
                placeDetails?.title,
                id,
                null,
                null,
                name,
                false
              );
              handleClickAway();
            }
          }}
          id={idSelector || 'add-to-trip-button'}
          onMouseOver={handleLocationHover}
          onMouseLeave={() => {
            if (sections?.length === 0) {
              setLocationPopperOpen(null);
            }
            dispatch(
              actions.View.setHighlightedSection({
                section: null,
              })
            );
          }}
          className={`${classes.listItem} ${classSelector || ''}`}
          style={{
            // to align the text, 12px(specified) + 2px(alignment spacing) with the 'new location' icon according to the designs
            paddingLeft: 14,
          }}
          alignItems="center">
          <ListItemIcon
            style={{
              minWidth: 0,
              marginRight: 4,
            }}>
            <LanguageOutlined
              style={{
                height: 14,
                width: 14,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={name}
            primaryTypographyProps={{ fontSize: '14px' }}
            className={classes.listItemText}
          />
          {sections.length > 0 && (
            <>
              <ListItemIcon
                style={{
                  minWidth: 0,
                }}>
                <ChevronRightRounded
                  style={{
                    height: 14,
                    width: 14,
                    ...(locationPopperOpen === id
                      ? {}
                      : { color: 'transparent' }),
                  }}
                />
              </ListItemIcon>
              <Popper
                id={`location-${id}`}
                open={locationPopperOpen === id}
                anchorEl={locationAnchor}
                placement="right"
                style={{
                  width: 'inherit',
                  zIndex: 1122,
                }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [-4, 8],
                    },
                  },
                ]}>
                <ClickAwayListener onClickAway={() => {}}>
                  <List
                    className={classes.list}
                    style={{ maxHeight: 100, maxWidth: 120 }}
                    onMouseLeave={() => {
                      setLocationPopperOpen(null);
                    }}>
                    {sections?.map((section) => {
                      return (
                        <ListItem
                          key={section.id}
                          onClick={() => {
                            handleAddActivity(
                              placeDetails?.title,
                              id,
                              section?.id,
                              section?.name,
                              name,
                              false
                            );
                            handleClickAway();
                          }}
                          onMouseOver={(e) => {
                            handleSectionHover(e, section?.id);
                            handleDropDownHover(e);
                          }}
                          onMouseLeave={(e) => {
                            dispatch(
                              actions.View.setHighlightedHeading({
                                section: null,
                              })
                            );
                            dispatch(
                              actions.View.setHighlightedSection({
                                section: null,
                              })
                            );
                            handleDropDownLeave(e);
                          }}
                          className={classes.listItem}
                          justify="center"
                          style={{ padding: '2px 12px' }}>
                          <ListItemText
                            primary={
                              section.name === ''
                                ? 'Unnamed Section'
                                : section?.name
                            }
                            primaryTypographyProps={{ fontSize: '14px' }}
                            className={classes.listItemText}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </ClickAwayListener>
              </Popper>
            </>
          )}
        </ListItem>
      );
    });

  const handleAddToTripClick = async (activityTitle, activityDescription) => {
    getTour()
      .onActiveTour([
        'emptyTripFlowWeb',
        'blankTripFlowWeb',
        'sampleTripFlowWeb',
        'quickStartTripFlowWeb',
      ])
      .onActiveStep(stepName.ADD_TO_TRIP_BUTTON)
      .closeTour()
      .goToNextStep();

    // getTour().onActiveStep(stepName.ADD_TO_TRIP_BUTTON).goToNextStep();

    // Here handles the custom logic to add a activity or destination under itinerary
    const isDestination =
      placeDetails &&
      placeDetails.types &&
      ['locality', 'political'].some((type) =>
        placeDetails.types.includes(type)
      );
    if (isDestination) {
      await handleAddLocation();
    } else {
      let locationId = null;
      let locationName = null;
      let sectionId = null;
      let sectionName = null;

      locationIds.find((locId) => {
        const location = locations[locId];
        if (location) {
          const { name } = location;
          // Find if the location name exists in the activity title or activity description
          if (
            activityTitle.includes(name) ||
            activityDescription.includes(name)
          ) {
            locationId = locId;
            locationName = name;
            sectionId =
              location?.thingsToDo?.length > 0
                ? location.thingsToDo[location.thingsToDo.length - 1]
                : null;
            if (sectionId) {
              // get the section name
              sectionName = thingsToDo[sectionId]?.name;
            }
            return true;
          }
        }
        return false;
      });

      if (locationId) {
        await handleAddActivity(
          activityTitle,
          locationId,
          sectionId,
          sectionName,
          locationName,
          true
        );
        getTour()
          .onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO)
          .openTour({ delay: 1000 });
      } else {
        // create a new mapPin with the new location and then add activity under that.
        const { city, state, country } = extractAddressComponents(
          additionalPlaceDetails?.addressComponents
        );
        // if address component doesn't include city then consider state and then country
        setCityName(city || state || country);
        getTour()
          .onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO)
          .openTour({ delay: 1000 });
      }
    }
  };

  return (
    <>
      {CustomButton ? (
        <CustomButton ref={ref} onClick={handleDropDownClick} />
      ) : (
        <div className={classes.buttonContainer}>
          {isAdded && !isCommandBarEnabled && (
            <div
              className={`${classes.trashIconContainer} ${hoverContentClass}`}>
              <DeleteOutlineOutlined
                className={classes.trashIcon}
                onClick={() => {
                  phTrackEvent({
                    event: EVENTS.PLAN_MAP_CARD.ACTIVITY_DELETE,
                  });
                  activeCardDetails?.locationId
                    ? setDeleteModalOpen(true)
                    : handleDeleteMapPin();
                }}
              />
            </div>
          )}
          <ButtonGroup
            variant="contained"
            ref={ref}
            {...buttonProps}
            sx={{
              boxShadow: 'none',
              backgroundColor: isAdded ? '#43A047' : '#ED702E',
              '& .MuiButtonGroup-grouped': {
                minWidth: 0, // Set minWidth for grouped buttons
                backgroundColor: isAdded ? '#43A047' : '#ED702E',
              },
            }}>
            <ButtonDefault
              sx={{
                fontSize: '14px',
                padding: '4px 8px',
                margin: 0,
                whiteSpace: 'nowrap',
                border: 'none !important',
                backgroundColor: isAdded ? '#43A047' : '#ED702E',
                '&:hover': {
                  backgroundColor: isAdded ? '#43A047' : '#D35E07',
                },
              }}
              disableFocusRipple
              disableRipple
              onClick={(e) => {
                // disable the button if the activity is already added to the trip
                if (isAdded) return handleDropDownClick(e);
                // if command bar is enabled then directly add the activity to the selected destination and section in nav bar
                if (isCommandBarEnabled) {
                  if (!activeLocationId) {
                    const isDestination =
                      placeDetails &&
                      placeDetails.types &&
                      ['locality', 'political'].some((type) =>
                        placeDetails.types.includes(type)
                      );
                    if (!isDestination) {
                      const { city, state, country } = extractAddressComponents(
                        additionalPlaceDetails?.addressComponents
                      );
                      // if address component doesn't include city then consider state and then country
                      setCityName(city || state || country);
                    } else {
                      handleAddLocation();
                    }
                  } else {
                    handleAddActivity(
                      placeDetails?.title,
                      activeLocationId,
                      activeSectionId,
                      thingsToDo[activeSectionId]?.name,
                      locations[activeLocationId]?.name,
                      false
                    );
                  }
                } else {
                  handleAddToTripClick(
                    placeDetails?.title,
                    placeDetails?.description
                  );
                }
              }}>
              {isAdded ? 'Added to trip' : 'Add to trip'}
            </ButtonDefault>
            <ButtonDefault
              onMouseOver={(e) =>
                !isCommandBarEnabled ? handleDropDownHover(e) : null
              }
              onMouseLeave={handleDropDownLeave}
              onClick={handleDropDownClick}
              size="small"
              sx={{
                backgroundColor: isAdded ? 'success.main' : '#ED702E',
                padding: '2px 2px',
                '&:hover': {
                  backgroundColor: isAdded ? 'success.main' : '#D35E07',
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: '0',
                  top: '30%',
                  height: '40%',
                  width: '1px',
                  backgroundColor: 'white',
                },
              }}>
              <ArrowDropDownRounded sx={{ padding: 0, margin: 0 }} />
            </ButtonDefault>
          </ButtonGroup>
        </div>
      )}
      <Popper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}
        onMouseOver={handleDropDownHover}
        onMouseLeave={handleDropDownLeave}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <List className={classes.list}>
            <ListItem
              key="create-new-location"
              onClick={async () => {
                await handleAddLocation();
                handleClickAway();
              }}
              onMouseOver={() => {
                setLocationPopperOpen('create-new-location');
              }}
              className={classes.listItem}
              style={{
                whiteSpace: 'nowrap',
                justifyContent: 'flex-start',
              }}>
              <ListItemIcon
                style={{
                  minWidth: 0,
                  marginRight: 4,
                }}>
                <AddCircleOutlineRounded
                  style={{
                    height: 14,
                    width: 14,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="New Location"
                primaryTypographyProps={{
                  fontSize: '14px',
                }}
              />
            </ListItem>
            <Divider style={{ marginTop: 4, marginBottom: 4 }} />
            {renderLocations()}
          </List>
        </ClickAwayListener>
      </Popper>
      <DeleteModal
        executeFunc={handleDeleteMapPin}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        emoji="😮"
        alertText="Are you sure?"
        descriptionText="Deleting this item will delete all the information nested under it."
        confirmText="Yes, delete it!"
        cancelText="No, keep it."
      />
    </>
  );
}

export default AddToTripButtonNew;
