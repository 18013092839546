import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Typography,
  IconButton,
  CircularProgress,
  Slider,
  Box,
} from '@mui/material';
import {
  CloseRounded,
  MoreHorizRounded,
  ZoomInRounded,
} from '@mui/icons-material';
import FileViewer from 'react-file-viewer';

import { useParams } from 'react-router-dom';
import OptionsMenu from '../../Menu/OptionsMenu/index';
import { getFileIcon, handleFileDelete } from '../../../../utils';
import actions from '../../../../redux/actions';
import { UploadIcon } from '../../../atoms/Icon';
import { updateTrip } from '../../../../redux/slices/Trips';
import DeleteModal from '../DeleteModal';
import PDFViewer from './pdfViewer';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9000,
  },
  container: {
    backgroundColor: '#FFFFFF',
    height: '70%',
    minHeight: 500,
    maxHeight: 658,
    borderRadius: 12,
    padding: 24,
    flexDirection: 'column',
    outline: 'none',
    display: 'flex',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      minWidth: 340,
      minHeight: 500,
    },
    [theme.breakpoints.up('md')]: {
      width: '55%',
    },
  },
  previewHeader: {
    display: 'flex',
    width: '100%',
    marginBottom: 8,
    marginLeft: 4,
  },
  previewFooter: {
    display: 'flex',
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  slider: {
    width: 120,
    color: '#8A8A8A',
  },
  previewContainer: {
    width: '100%',
    height: 100,
    flex: '1 1 auto',
    display: 'flex',
    backgroundColor: '#F4F4F4',
    borderRadius: 12,
    marginBottom: 8,
    padding: 1,
    overflow: 'hidden',
    '& > div': {
      borderRadius: 12,
      width: '100%',
    },
  },
  icon: {
    padding: 2,
  },
  titleWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    width: 'calc(100% - 24px)',
  },
  iframe: {
    width: '100%',
    borderRadius: 12,
    '& > *': {
      backgroundColor: '#F4F4F4',
    },
  },
  fileLogo: {
    marginRight: 8,
    height: 20,
    width: 20,
    viewBox: '0 0 20 20',
  },
  tabContainer: {
    display: 'flex',
    borderBottom: '1px solid #D0D0D0',
    marginBottom: 4,
    overflowX: 'scroll',
    padding: 0,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    cursor: 'pointer',
    color: '#D0D0D0',
    padding: '8px 8px 8px 4px',
    marginRight: 8,
    flexShrink: 0,
  },
  tabIcon: {
    height: 20,
    width: 20,
    marginRight: 8,
  },
  activeTab: {
    fontWeight: 700,
    borderBottom: '2px solid #000000',
    color: '#222222',
  },
}));

// TODO: Create custom package for file previewer on our end
function FilePreviewModal({
  fileUploadTrigger,
  detachFunc,
  bladeView,
  itemId,
  isImported = false,
}) {
  const classes = useStyles();
  const [scale, setScale] = useState(1.2);

  const dispatch = useDispatch();
  const openFile = useSelector((state) => state.Files.openFile);
  const Files = useSelector((state) => state.Files);
  const allFiles = Files.files;
  const multipleFiles = useSelector((state) => state.Files.multipleFiles);

  const [optionsMenuAnchor, setOptionsMenuAnchor] = useState(null);
  const [loading, setLoading] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleClose = () => {
    dispatch(actions.Files.setFilePreview(null));
    dispatch(actions.Files.setMultipleFilesPreview([]));
  };

  const handleTabChange = (file) => {
    dispatch(
      actions.Files.setFilePreview({
        extension: allFiles[file].url.split('?')[0]?.split('.')?.pop(),
        ...allFiles[file],
      })
    );
  };

  const { slug: tripId } = useParams();
  const tripFiles = useSelector((state) => state.Trips.trips[tripId]?.files);
  const fileRelations = useSelector(
    (state) => state.Files.fileRelations[tripId]
  );

  const Transportation = useSelector((state) => state.Transportation);

  const handleTriggerDeleteFile = () => {
    const newMultipleFiles = multipleFiles.filter(
      (file) => file !== openFile?.id
    );

    handleFileDelete(openFile, tripId, dispatch, Transportation, Files);
    dispatch(actions.Files.setMultipleFilesPreview(newMultipleFiles));
    if (newMultipleFiles?.length === 0) {
      dispatch(actions.Files.setFilePreview(null));
    }
  };

  const handleFilDetach = async () => {
    const newFiles = multipleFiles?.filter((file) => file !== openFile?.id);
    detachFunc(newFiles, tripId);
    if (
      fileRelations?.filter(
        (file) => file?.fileId === openFile?.id && file?.attachedToId !== itemId
      )?.length === 0
    ) {
      dispatch(
        updateTrip({
          variables: {
            id: tripId,
            files: [...tripFiles, openFile?.id],
          },
          addFiles: [openFile?.id],
        })
      );
    }
    dispatch(actions.Files.setMultipleFilesPreview(newFiles));
    if (newFiles?.length === 0) {
      handleClose();
    }
  };

  const downloadFile = () => {
    if (!openFile.url) return;
    setLoading(true);

    // creating a local object blob and programatically creating a link to download the file and click it.
    fetch(openFile.url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = openFile.name;
        link.click();
        setLoading(false);
      });
  };

  const Logo = getFileIcon(openFile?.extension);

  // TODO: create @pilotplans/react-file-viewer and use that to do this operation
  // To handle text file render
  const [txtFileContent, setTextFileContent] = useState(null);
  const resolveTxtFile = async (url) => {
    if (txtFileContent && txtFileContent.url === url) return;
    const txt = await fetch(url)
      .then((res) => res.text())
      .then((text) => text);
    setTextFileContent({
      content: txt,
      source: url,
    });
  };

  function ErrorComponent({ url }) {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        We cannot preview this file type for you right now, please download or
        click{' '}
        <a href={url} rel="noreferrer" target="_blank">
          here
        </a>{' '}
        to view it
      </div>
    );
  }

  return (
    <Modal
      className={classes.modal}
      open={Boolean(openFile)}
      onClose={handleClose}
      keepMounted>
      <div className={classes.container}>
        {
          // File information header
        }
        <div className={classes.previewHeader}>
          <div className={classes.titleWrapper}>
            {multipleFiles?.length > 1 ? (
              <Typography variant="h6" style={{ paddingRight: 24 }}>
                Files preview
              </Typography>
            ) : (
              <>
                {loading ? (
                  <CircularProgress
                    size={20}
                    style={{
                      marginRight: 8,
                    }}
                  />
                ) : (
                  <Logo className={classes.fileLogo} />
                )}
                <Typography
                  variant="h6"
                  style={{
                    paddingRight: 24,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}>
                  {openFile?.name}
                </Typography>
              </>
            )}
            {!isImported && (
              <>
                {fileUploadTrigger && (
                  <IconButton
                    className={classes.icon}
                    onClick={() => {
                      fileUploadTrigger(true);
                      handleClose();
                    }}
                    style={{ marginRight: 8 }}>
                    <UploadIcon style={{ height: 18, width: 18 }} />
                  </IconButton>
                )}

                {multipleFiles?.length <= 1 && (
                  <IconButton
                    className={classes.icon}
                    onClick={(e) => {
                      setOptionsMenuAnchor(e.currentTarget);
                    }}>
                    <MoreHorizRounded />
                  </IconButton>
                )}
              </>
            )}
          </div>

          <IconButton className={classes.icon} onClick={() => handleClose()}>
            <CloseRounded style={{ color: '#1D1D1D' }} />
          </IconButton>
        </div>
        {multipleFiles?.length > 1 && (
          <Box className={classes.tabContainer}>
            {multipleFiles.map((file) => {
              const LogoIcon = getFileIcon(
                allFiles[file]?.url.split('?')[0]?.split('.')?.pop()
              );
              return (
                <Box
                  className={`${classes.tab} ${
                    openFile?.id === file && classes.activeTab
                  }`}
                  onClick={() => handleTabChange(file)}>
                  <LogoIcon className={classes.tabIcon} />
                  {allFiles[file]?.name}
                  {openFile?.id === file && (
                    <IconButton
                      className={classes.icon}
                      onClick={(e) => {
                        setOptionsMenuAnchor(e.currentTarget);
                      }}
                      sx={{ marginLeft: '8px' }}>
                      <MoreHorizRounded />
                    </IconButton>
                  )}
                </Box>
              );
            })}
          </Box>
        )}

        {
          // File preview - using react-file-viewer
        }
        <div className={classes.previewContainer}>
          {openFile &&
            (openFile.extension === 'txt' ? (
              <div
                style={{
                  overflow: 'scroll',
                  whiteSpace: 'pre',
                  padding: '8px 0px 0px 8px',
                }}>
                <Typography>
                  {resolveTxtFile(openFile.url) &&
                    (txtFileContent && txtFileContent.source === openFile.url
                      ? txtFileContent.content
                      : 'Loading')}
                </Typography>
              </div>
            ) : openFile.extension === 'pdf' ? (
              <PDFViewer
                scale={scale}
                key={openFile.id}
                url={`${openFile.url}&type=.${openFile.extension}`}
              />
            ) : (
              <FileViewer
                key={openFile.id}
                fileType={openFile.extension}
                filePath={`${openFile.url}&type=.${openFile.extension}`}
                unsupportedComponent={() => (
                  <ErrorComponent url={openFile.url} />
                )}
                disableVisibilityCheck
                errorComponent={() => <ErrorComponent url={openFile.url} />}
              />
            ))}
        </div>

        {openFile?.extension === 'pdf' && (
          <div className={classes.previewFooter}>
            <ZoomInRounded style={{ color: '#8A8A8A', marginRight: '8px' }} />
            <Slider
              defaultValue={1.2}
              step={0.25}
              min={0.5}
              max={2.0}
              onChange={(e, newScale) => setScale(newScale)}
              className={classes.slider}
              size="small"
            />
            <Typography variant="h5" color="#4E4E4E" marginLeft="12px">
              {scale?.toFixed(1)}x
            </Typography>
          </div>
        )}

        {
          // Modals
        }
        <OptionsMenu
          id="file-menu"
          state={optionsMenuAnchor}
          setState={setOptionsMenuAnchor}
          callbacks={
            bladeView
              ? [downloadFile, handleFilDetach, () => setDeleteModalOpen(true)]
              : [downloadFile, () => setDeleteModalOpen(true)]
          }
          options={
            bladeView
              ? ['Download', 'Detach', 'Delete']
              : ['Download', 'Delete']
          }
          menuItemProps={{
            style: {
              fontSize: 12,
              padding: '4px 16px',
            },
          }}
          menuProps={{
            MenuListProps: {
              style: {
                padding: '4px 0',
              },
            },
          }}
        />

        <DeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          executeFunc={() => {
            handleTriggerDeleteFile();
            setDeleteModalOpen(false);
          }}
          alertText="Are you sure?"
          descriptionText="This action will detach your file from all itinerary items and remove your file from Pilot permanently."
          confirmText="Delete"
          cancelText="Cancel"
          emoji="⚠️"
        />
      </div>
    </Modal>
  );
}

export default FilePreviewModal;
