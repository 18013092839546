import { useDispatch, useSelector } from 'react-redux';
import { EVENTS, phTrackEvent } from '../../../analytics';
import actions from '../../../redux/actions';
import { deleteAccommodation } from '../../../redux/slices/Accommodation';
import { deleteActivity } from '../../../redux/slices/Activity';
import {
  updateImportAttachedTo,
  updateFile,
} from '../../../redux/slices/Files';
import { deleteTransportation } from '../../../redux/slices/Transportation';
import { updateTrip } from '../../../redux/slices/Trips';

function useSmartImport({
  attachedToItem,
  itemType,
  isForwardedFile,
  fileId,
  tripId,
  fileIndex,
  itemIndex,
  importId,
}) {
  const trip = useSelector((state) => state.Trips.trips[tripId]);
  const transports = useSelector((state) => state.Transportation.transports);
  const accommodations = useSelector((state) => state.Accommodation.hotels);
  const activties = useSelector((state) => state.Activity.todos);

  const dispatch = useDispatch();

  const attachToItem = async (itemId) => {
    await dispatch(
      updateImportAttachedTo({
        variables: { importId, itemIndex, attachedToItem },
      })
    );
    if (isForwardedFile) {
      dispatch(
        actions.Auth.updateForwardedFileImportItemAttachedToItemId({
          index: fileIndex,
          itemIndex,
          attachedToItemId: attachedToItem,
        })
      );
      await dispatch(
        updateFile({
          variables: {
            id: fileId,
            attachedToID: itemId ? tripId : '',
            tripId: attachedToItem ? tripId : '',
            attachedToType: attachedToItem ? 'Trip' : '',
          },
        })
      );
      await dispatch(
        updateTrip({
          variables: {
            id: tripId,
            files: itemId
              ? [...(trip?.files || []), fileId]
              : trip?.files || [],
          },
          addFiles: !itemId ? [fileId] : [],
        })
      );
    }
  };
  const detachItem = async () => {
    phTrackEvent({
      event: EVENTS.SMART_IMPORT.PLAN_IMPORT_HISTORY_BLADE_REMOVE,
    });
    await attachToItem(null);
    if (['bus', 'train', 'other', 'flight'].includes(itemType)) {
      await dispatch(
        deleteTransportation({
          variables: { id: attachedToItem, tripId },
          extra: { tripId },
        })
      );
    }
    if (itemType === 'activity') {
      const attachedToSectionId = activties[attachedToItem]?.thingsToDoId;
      await dispatch(
        deleteActivity({
          variables: { id: attachedToItem, thingsToDoId: attachedToSectionId },
          sectionId: attachedToSectionId,
        })
      );
    } else {
      const attachedLoacationId = accommodations[attachedToItem]?.locationId;
      await dispatch(
        deleteAccommodation({
          variables: { id: attachedToItem, locationId: attachedLoacationId },
          extra: { locationId: attachedLoacationId },
        })
      );
    }
  };

  const getAttachedItemName = () => {
    if (['bus', 'train', 'other', 'flight'].includes(itemType)) {
      if (transports[attachedToItem]?.details[0]) {
        return (
          transports[attachedToItem]?.details[0]?.flightNumber ||
          transports[attachedToItem]?.details[0]?.toAirport
        );
      }
    }
    return (
      accommodations[attachedToItem]?.name ||
      activties[attachedToItem]?.title ||
      null
    );
  };
  return {
    detachItem,
    getAttachedItemName,
  };
}

export default useSmartImport;
