/**
 * This file lists the thunks that needs to be imported into multiple slices.
 * This is done to resolve issues with circular dependencies that may arise.
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import QUERY from '../../graphql/queries';
import graphqlClient from '../../graphql/index';
import getAsyncThunk from '../helpers';
import { trackEvents, Events } from '../../intercom';

export const createSection = getAsyncThunk(
  'SECTION/createSection',
  QUERY.CREATE_THINGS_TODO
);

export const createTripMapPin = getAsyncThunk(
  'MAP/createTripMapPin',
  QUERY.CREATE_TRIP_MAP_PIN
);

export const handleDndItems = createAsyncThunk(
  'DragAndDrop/items',
  async ({ source, destination, draggableId }) => {
    trackEvents(Events.ItineraryItemReordered);
    await graphqlClient.mutate({
      mutation: QUERY.UPDATE_TRIP_ITEMS_DND,
      variables: {
        tripId: source.droppableId,
        itemId: draggableId,
        targetIndex: destination.index,
      },
    });
  }
);

export const handleDndSections = createAsyncThunk(
  'DragAndDrop/sections',
  async ({ source, destination, draggableId }) => {
    await graphqlClient.mutate({
      mutation: QUERY.UPDATE_LOCATION_ITEMS_DND,
      variables: {
        itemId: draggableId,
        itemType: 'thingsToDo',
        sourceId: source.droppableId,
        targetId: destination.droppableId,
        targetIndex: destination.index,
      },
    });
  }
);

export const handleDndHotels = createAsyncThunk(
  'DragAndDrop/hotels',
  async ({ source, destination, draggableId }) => {
    await graphqlClient.mutate({
      mutation: QUERY.UPDATE_LOCATION_ITEMS_DND,
      variables: {
        itemId: draggableId,
        itemType: 'hotels',
        sourceId: source?.droppableId?.split('-accommodation')[0],
        targetId: destination?.droppableId?.split('-accommodation')[0],
        targetIndex: destination.index,
      },
    });
  }
);

export const handleDndActivities = createAsyncThunk(
  'DragAndDrop/activities',
  async ({ source, destination, draggableId }) => {
    graphqlClient.mutate({
      mutation: QUERY.UPDATE_TODOS_DND,
      variables: {
        itemId: draggableId,
        sourceId: source.droppableId,
        targetId: destination.droppableId,
        targetIndex: destination.index,
      },
    });
  }
);

export const clearMapPinsFromSection = createAsyncThunk(
  'MAP/clearMapPinsFromSection',
  ({ sectionId }, { getState }) => {
    const mapPinsToDelete = [];
    const state = getState();
    state.Section.sections[sectionId]?.todos?.forEach((activityId) => {
      const activity = state.Activity.todos[activityId];
      if (activity?.mapPin) {
        mapPinsToDelete.push(activity.mapPin);
      }
    });
    return mapPinsToDelete;
  }
);

export const clearMapPinsFromLocation = createAsyncThunk(
  'MAP/clearMapPinsFromLocation',
  ({ locationId }, { getState }) => {
    const mapPinsToDelete = [];
    const state = getState();
    const location = state.Location.locations[locationId];
    location?.thingsToDo?.forEach((sectionId) => {
      if (!sectionId) return;
      state.Section.sections[sectionId]?.todos?.forEach((activityId) => {
        const activity = state.Activity.todos[activityId];
        if (activity?.mapPin) {
          mapPinsToDelete.push(activity.mapPin);
        }
      });
    });

    location?.hotels?.forEach((hotelId) => {
      const hotel = state.Accommodation.hotels[hotelId];
      if (hotel?.mapPin) {
        mapPinsToDelete.push(hotel.mapPin);
      }
    });

    if (location?.mapPin) {
      mapPinsToDelete.push(location.mapPin);
    }

    return mapPinsToDelete;
  }
);

export default {
  createSection,
};
