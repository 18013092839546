import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from '../../../../redux/actions';
import { importModelViews } from '../../../../redux/slices/View';
import FileRow from '../../../organisms/FilesTab/FileRowV2';
import { MapProvider } from '../../../organisms/MapUtils';
import ReviewStepFooter from './ReviewStepFooter';

function ReviewStep() {
  const dispatch = useDispatch();
  const tripId = useParams().slug;
  const forwardedFiles = useSelector(
    (state) => state?.Auth?.userData?.user?.forwardedFiles || []
  );
  const { importIdToReview, reviewQueue, reviewsDone, reviewsPending } =
    useSelector((state) => state.View);
  const tripFiles = useSelector(
    (state) =>
      Object.values(state.Files.files).filter(
        (file) => file?.tripId === tripId
      ),
    shallowEqual
  );

  const handleDone = () => {
    if (reviewQueue?.length === 0) {
      dispatch(actions.View.removeImportIdToReview());
      dispatch(actions.View.setImportsModelView(importModelViews.import));
    } else {
      dispatch(actions.View.popReviewQueue());
      if (reviewQueue?.length === 0) {
        dispatch(actions.View.removeImportIdToReview());
        dispatch(actions.View.setImportsModelView(importModelViews.import));
      }
    }
  };

  useEffect(() => {
    if (!importIdToReview) {
      dispatch(actions.View.setImportsModelView(importModelViews.import));
    }
  }, [importIdToReview, reviewQueue]);

  return (
    <Stack direction="column" height="100%" justifyContent="space-between">
      <Stack
        alignItems="center"
        direction="column"
        width="100%"
        px="24px"
        sx={{
          overflowY: 'scroll',
        }}
        height="100%">
        <Typography color="#8A8A8A" fontSize="14px">
          Step 2
        </Typography>
        <Stack
          mt={1}
          direction="row"
          alignItems="center"
          gap={1}
          justifyContent="center">
          <Typography fontSize="24px">Review and Add </Typography>
          {reviewsPending > 1 && (
            <Typography fontSize="24px" color="#8A8A8A">
              ({reviewsDone}/{reviewsPending})
            </Typography>
          )}
        </Stack>

        <Box
          sx={{
            borderBottom: '1px solid #DEDDDD',
            width: '100%',
            marginTop: '32px',
          }}
        />
        <MapProvider>
          {[...tripFiles, ...forwardedFiles].length > 0 ? (
            [...tripFiles, ...forwardedFiles]
              .filter((file) => file.import?.id === importIdToReview)
              .map((file, index) => (
                <FileRow
                  showAsAddedonAdded
                  showOnlyFileAndImports
                  isForwardedFile={file?.type === 'EmailAttachment'}
                  isModalView
                  file={file}
                  fileIndex={index}
                  key={file.id}
                  handleTriggerDeleteFile={() => {}}
                  tripId={tripId}
                  tripFiles={[]}
                />
              ))
          ) : (
            <Typography>No imports found</Typography>
          )}
        </MapProvider>
      </Stack>
      <ReviewStepFooter onDone={handleDone} />
    </Stack>
  );
}

export default ReviewStep;
