import React, { useState } from 'react';
import {
  IconButton,
  Snackbar,
  Typography,
  SnackbarContent,
  Stack,
  Button,
  CircularProgress,
} from '@mui/material';
import { HistoryRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { CopyIcon, RefreshIconSquare } from '../../../atoms/Icon';
import { importModelViews } from '../../../../redux/slices/View';
import actions from '../../../../redux/actions';
import { getUserForwardedFiles } from '../../../../redux/slices/Auth';
import { EVENTS, phTrackEvent } from '../../../../analytics';

const email = 'import@pilotplans.com';
export default function ImportFilesFooter() {
  const [open, setOpen] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const { firebaseUser } = useSelector((state) => state.Auth);
  const forwardedFilesRefreshCount = useSelector(
    (state) => state.View.forwardedFilesRefreshCount
  );

  const [showReportBug, setShowReportBug] = useState(false);
  const dispatch = useDispatch();
  const setForwardedFilesRefreshCount = (count) =>
    dispatch(actions.View.setForwardedFilesRefreshCount(count));
  const handleCopy = async () => {
    phTrackEvent({
      event: EVENTS.SMART_IMPORT.PLAN_IMPORT_COPYEMAIL_CLICK,
    });
    await navigator.clipboard.writeText(email);
    setOpen(true);
  };

  const reportBug = () => {
    phTrackEvent({
      event: EVENTS.SMART_IMPORT.PLAN_IMPORT_MISSING_EMAIL_SUBMIT,
    });
    window.open('https://forms.gle/YxBh5j1wadZ88qif7', '_blank');
  };
  const handleRefresh = async () => {
    if (forwardedFilesRefreshCount > 2) {
      phTrackEvent({
        event: EVENTS.SMART_IMPORT.PLAN_IMPORT_MISSING_EMAIL_POPUP_OPEN,
      });
      setShowReportBug(true);
      return;
    }
    phTrackEvent({
      event: EVENTS.SMART_IMPORT.PLAN_IMPORT_REFRESH_CLICK,
    });
    setRefreshLoading(true);
    const forwardedFilesRes = await dispatch(
      getUserForwardedFiles({
        variables: {
          id: firebaseUser.uid,
        },
      })
    );
    setRefreshLoading(false);
    setForwardedFilesRefreshCount(forwardedFilesRefreshCount + 1);
    const forwardedFiles =
      forwardedFilesRes.payload.getUser?.forwardedFiles || [];
    const newFile = forwardedFiles.find(
      (file) => file?.import?.status === 'pending'
    );
    if (newFile) {
      dispatch(actions.View.setImportsModelView(importModelViews.review));
      dispatch(actions.View.setImportIdToReviewIfNotSet(newFile?.import?.id));
    }
  };

  const setHistoryView = () =>
    dispatch(actions.View.setImportsModelView(importModelViews.history));

  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      mt={2}>
      <Stack alignItems="center">
        <Typography color="#8a8a8a" fontSize="14px" mb="7px">
          Or
        </Typography>
        <Stack
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
          }}
          alignItems="center"
          gap="1px">
          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
            Forward your bookings or files via email to{' '}
          </Typography>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center">
            <Typography
              component="span"
              sx={{
                color: '#ED702E',
                marginLeft: '4px',
                marginRight: '4px',
                textDecoration: 'underline',
              }}>
              {email}
            </Typography>
            <IconButton
              onClick={handleCopy}
              size="small"
              sx={{
                color: '#ff6b42',
                '&:hover': {
                  backgroundColor: 'rgba(255, 107, 66, 0.04)',
                },
              }}>
              <CopyIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        pb="24px"
        // flexDirection="row"
        sx={{
          gap: {
            xs: '8px',
            sm: '2px',
          },
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
        px="24px">
        <Button
          onClick={setHistoryView}
          sx={{
            backgroundColor: 'white',
            color: '#4E4E4E',
            border: '1px solid #D9D9D9',
            height: '30px',
            padding: '0px 12px',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              color: '#4E4E4E',
            },
          }}
          startIcon={<HistoryRounded sx={{ color: '#4E4E4E' }} />}
          variant="text">
          Import History
        </Button>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography color="#8a8a8a" fontSize="13px">
            Already forwarded your email?
          </Typography>
          <IconButton
            sx={{
              borderRadius: '12px',
              border: '1px solid #D9D9D9',
              height: '28px',
              width: '28px',
              padding: '2px',
            }}
            onClick={handleRefresh}
            disabled={refreshLoading}>
            {refreshLoading ? (
              <CircularProgress size={20} />
            ) : (
              <RefreshIconSquare />
            )}
          </IconButton>
        </Stack>
      </Stack>
      <Snackbar
        sx={{
          position: 'absolute',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        message="Email copied to clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <SnackbarContent
          sx={{
            width: '345px',
            backgroundColor: '#43A047',
            color: '#fff',
          }}
          message={`${email} copied to clipboard`}
        />
      </Snackbar>
      <Snackbar
        sx={{
          width: '345px',
          marginRight: '120px',
          position: 'absolute',
        }}
        open={showReportBug}
        autoHideDuration={4000}
        onClose={() => setShowReportBug(false)}
        message="Email copied to clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <SnackbarContent
          sx={{
            backgroundColor: '#FFDF97',
            color: '#4E4E4E',
            width: '345px',
          }}
          message={
            <Stack
              width="345px"
              direction="row"
              pr="16px"
              alignItems="center"
              height="22px"
              justifyContent="space-between"
              gap={1}>
              <Typography>Can’t find your email?</Typography>
              <Button
                sx={{
                  color: '#4E4E4E',
                  backgroundColor: 'transparent',
                  paddingRight: '16px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#4E4E4E',
                  },
                }}
                onClick={reportBug}>
                Submit Report
              </Button>
            </Stack>
          }
        />
      </Snackbar>
    </Stack>
  );
}
