import { useState } from 'react';
import TransportationBladeModifier from './TransportationBladeModifier';

function TrasportationModifier({
  open,
  setOpen,
  setItem,
  newFlight,
  defaultFlightProps = {},
  setTransport = () => {},
  id,
  type = 'flight',
}) {
  const [clickedAway, setClickedAway] = useState(false);
  const [newFlightIds, setNewFlightIds] = useState(['new-flight']);

  const [flightList, setFlightList] = useState(
    defaultFlightProps?.details || [
      {
        title: '',
        notes: '',
        link: '',
        cost: null,
        currency: null,
        toAirport: '',
        fromAirport: '',
        toDate: null,
        fromDate: null,
      },
    ]
  );

  const handleCreateTransportation = async () => {};

  const handleClickOutside = async () => {
    setNewFlightIds([]);
    if (!clickedAway) {
      if (newFlight) {
        setClickedAway(true);
        await handleCreateTransportation();
      }
    }
  };

  const handleUpdateTransportationBlade = (data, flightIndex) => {
    try {
      const updatedFlightList = [
        ...flightList.slice(0, flightIndex),
        {
          ...flightList[flightIndex],
          ...data,
        },
        ...flightList.slice(flightIndex + 1),
      ];

      setFlightList(updatedFlightList);
      // Update transport state with new flight list
      const updatedTransport = {
        ...defaultFlightProps,
        details: updatedFlightList,
      };
      setTransport(updatedTransport);
      setItem(updatedTransport);
    } catch (err) {
      // Handle error
    }
  };

  return (
    <div
    // ref={divRef}
    >
      <div>
        {flightList?.map((flight, idx) => (
          <TransportationBladeModifier
            key={flight.id}
            id={flight.id}
            transportId={id}
            open={open}
            setOpen={setOpen}
            newFlight={newFlight}
            defaultFlightProps={flight}
            type={type}
            index={idx}
            handleUpdateTransportationBlade={handleUpdateTransportationBlade}
            canShowDeleteIcon={flightList?.length > 1}
            handleClickOutside={handleClickOutside}
            newFlightIds={newFlightIds}
            setNewFlightIds={setNewFlightIds}
          />
        ))}
      </div>
    </div>
  );
}

export default TrasportationModifier;
