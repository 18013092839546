import { Grid, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OutlinedFlagRounded } from '@mui/icons-material';

import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import TodoHeader from './TodoSectionHeader';
import TodoList from '../todo/TodoList';
import AddTodo from '../todo/AddTodo';
import AddTodoSection from './AddTodoSection';
import { OrangeOutlinedButton } from '../../../atoms/Button/index';

const useTodoSectionStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    boxShadow: 'none',
    marginTop: 16,
  },
  isDragging: {
    backgroundColor: '#F4F4F4',
  },
  dragContainer: {
    padding: '1% 1% 2% 0%',
    border: `2px solid ${palette.primary.light}`,
    borderRadius: 8,
    marginLeft: 'calc(3.6rem + 15px)',
  },
  dragInactiveContainer: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 8,
  },
  todoSection: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'calc(3.6rem - 20px)',
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 15px + 8px)',
    },
  },
  dragSpacing: {
    marginLeft: 12,
  },
}));

function DraggableTodoSection({
  sectionId,
  locationId,
  locationName,
  index,
  tripId,
  isDragging,
  isSectionHighlight,
  locationBias,
  handleThingsToDoButtonClick,
}) {
  const classes = useTodoSectionStyles();
  const column = useSelector((state) => state.Section.sections[sectionId]);
  const todos = useSelector((state) => state.Activity.todos);
  const tasks = column && column?.todos.filter((todo) => todos[todo]);
  return (
    <Draggable
      key={sectionId}
      draggableId={sectionId}
      index={index}
      type="SECTION"
      disableInteractiveElementBlocking>
      {(provided, snapshot) => (
        <div
          className={classes.todoSection}
          ref={provided.innerRef}
          {...provided.draggableProps}>
          <TodoSection
            sectionId={sectionId}
            tasks={tasks}
            column={column}
            provided={provided}
            tripId={tripId}
            index={index}
            locationId={locationId}
            locationName={locationName}
            handleThingsToDoButtonClick={handleThingsToDoButtonClick}
            isDragging={snapshot.isDragging}
            isSectionHighlight={isSectionHighlight}
            isLocationDragging={isDragging}
            locationBias={locationBias}
          />
        </div>
      )}
    </Draggable>
  );
}

function TodoSection({
  tripId,
  sectionId,
  locationId,
  locationName,
  provided,
  column,
  tasks,
  index,
  isDragging,
  isSectionHighlight,
  isLocationDragging,
  locationBias,
  handleThingsToDoButtonClick,
}) {
  const classes = useTodoSectionStyles();
  const containsLocalTodo = tasks.some((task) => task.includes('local-todo'));

  return (
    <Card
      className={`${classes.root} ${
        isDragging || isLocationDragging ? classes.isDragging : ''
      } ${isDragging ? classes.dragContainer : ''} 
      `}>
      <TodoHeader
        locationId={locationId}
        sectionId={sectionId}
        column={column}
        dragHandleProps={provided.dragHandleProps}
        index={index}
        isDragging={isDragging || isLocationDragging}
        isSectionHighlight={isSectionHighlight}
      />
      <Grid className={classes.spacing}>
        <Droppable
          droppableId={column.id}
          type="ACTIVITY"
          direction="vertical"
          ignoreContainerClipping>
          {(droppableProvided) => (
            <div
              className={classes.dragInactiveContainer}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}>
              <TodoList
                todos={tasks}
                column={column}
                tripId={tripId}
                isDragging={isDragging || isLocationDragging}
                isSectionHighlight={isSectionHighlight}
                locationId={locationId}
                locationBias={locationBias}
              />
              {droppableProvided.placeholder}
              {column.id !== 'local-section' ? (
                <div
                  className={`${classes.buttonContainer} ${
                    isLocationDragging || isDragging || isSectionHighlight
                      ? classes.dragSpacing
                      : null
                  }`}>
                  <div style={{ display: 'flex' }}>
                    <AddTodo
                      sectionId={sectionId}
                      column={column}
                      locationId={locationId}
                    />
                    <AddTodoSection
                      locationId={locationId}
                      sectionId={sectionId}
                      index={index}
                      style={{
                        marginLeft: 8,
                      }}
                    />
                  </div>
                  {containsLocalTodo && (
                    <div style={{ flex: 0, marginLeft: 'auto', marginTop: 5 }}>
                      <OrangeOutlinedButton
                        onClick={handleThingsToDoButtonClick}
                        startIcon={
                          <OutlinedFlagRounded sx={{ height: 14, width: 14 }} />
                        }
                        sx={{
                          padding: '2px 8px',
                          fontSize: 12,
                          borderRadius: '20px',
                        }}>
                        {`Things to do in ${locationName}`}
                      </OrangeOutlinedButton>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          )}
        </Droppable>
      </Grid>
    </Card>
  );
}

export default DraggableTodoSection;
