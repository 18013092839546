import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { pathToRegexp } from 'path-to-regexp';
import { PostHogFeature } from 'posthog-js/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import * as Sentry from '@sentry/react';
import ErrorComponent from './components/molecules/Feedback/ErrorComponent';
import { firebaseAuth } from './provider/AuthProvider';
import { auth } from './firebase/FirebaseIndex';
import { intercomInit, Events } from './intercom';
import MainNavbar from './components/organisms/Navbar/MainNavbar';
import LoadingLayout from './components/templates/LoadingLayout';
import actions from './redux/actions';
import { identifyUser, phTrackEvent } from './analytics';
import pilotTheme from './theme/index';
import './app.css';
import { getUser } from './redux/slices/Auth';
import { getInvitations, getTrips } from './redux/slices/Trips';
import { AuthRoutes, AppRoutes, VerifyRoutes } from './routes';
import UserAlerts from './provider/UserAlerts';
import FLAGS from './featureFlags';

function App() {
  const { toSignup, setToSignup } = useContext(firebaseAuth);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const firebaseUser = useSelector((state) => state.Auth.firebaseUser);
  const userData = useSelector((state) => state.Auth.userData);
  const authLoading = useSelector((state) => state.Auth.loading);
  const globalError = useSelector((state) => state.Error);

  const validRedirectPrivate = (path) => {
    // function to update redirectURL if it's not part of the public routes. Keeps track of the latest private route.
    const privateRoutes = [
      '/trips',
      '/trips/:slug/:tab',
      '/join/:slug',
      // '/wishlist',
      // '/friends',
      // '/activities',
      // '/settings',
      // '/profile/:id',
      // '/discover',
      // '/discover/:slug',
    ];
    return privateRoutes.some((route) => pathToRegexp(route).test(path));
  };

  // setting states and initalising user data
  const initateUserSession = (authUser) => {
    identifyUser(authUser);
    dispatch(getUser({ id: authUser.uid }));
    dispatch(getTrips(authUser.uid));
    dispatch(getInvitations({}));
  };

  // checking for current authentication status
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userAuth) => {
      if (userAuth) {
        dispatch(actions.Auth.setFirebaseUser(userAuth));
        // Redirecting users to appropriate starting urls
        const redirectURL = window.localStorage.getItem('redirectURL');
        if (userAuth.emailVerified && redirectURL) {
          navigate(redirectURL);
          window.localStorage.removeItem('redirectURL');
        }
      } else {
        dispatch(actions.Auth.reset());
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  // Checks to await for async auth load as well as firebase setup
  useEffect(() => {
    if (firebaseUser && !authLoading) initateUserSession(firebaseUser);
  }, [authLoading, firebaseUser]);

  // page view tracker for posthog
  useEffect(() => {
    phTrackEvent({
      event: '$pageview',
      meta: {
        path: location?.pathname,
      },
    });
  }, [location]);

  useEffect(() => {
    const redirect = location.pathname + location.search;
    if (
      !firebaseUser &&
      validRedirectPrivate(location.pathname) &&
      redirect !== '/settings'
    ) {
      window.localStorage.setItem('redirectURL', redirect);
    }
  }, [firebaseUser, location]);

  let routes;

  if (globalError.error && globalError.type === 'FULLSCREEN') {
    routes = <ErrorComponent error={globalError.error} />;
  } else if (isLoading || userData.status === 'LOADING') {
    routes = <LoadingLayout />;
  } else if (!firebaseUser) {
    // routes for unauthenticated users
    let defaultAuthRoute = '/continue';
    if (toSignup === true) {
      setToSignup(false);
      defaultAuthRoute = '/signup';
    }
    routes = <AuthRoutes defaultAuthRoute={defaultAuthRoute} />;
  } else if (userData.error) {
    routes = <ErrorComponent />;
  } else if (
    (!firebaseUser?.emailVerified || !firebaseUser.email) &&
    userData.status === 'SUCCESS'
  ) {
    // routes for authenticated, but unverified users.
    routes = <VerifyRoutes />;
  } else if (userData.status === 'SUCCESS') {
    intercomInit({
      name: firebaseUser.displayName,
      email: firebaseUser.email,
      user_id: firebaseUser.uid,
    });
    // routes for authenticated and verified users.
    const isCreateATripRoute = location.pathname.includes('/create-a-trip');
    const isAccetTripRoute = location.pathname.includes('/join');

    routes = (
      <>
        {!(isCreateATripRoute || isAccetTripRoute) && <MainNavbar />}
        <AppRoutes />
      </>
    );
  }

  return (
    <StyledEngineProvider injectFirst>
      <Sentry.ErrorBoundary
        fallback={ErrorComponent}
        onError={(e) => window?.Intercom('trackEvent', Events.RanIntoBug, e)}>
        <ThemeProvider theme={pilotTheme}>
          <CssBaseline />
          <PostHogFeature flag={FLAGS.SMART_IMPORT} match>
            <UserAlerts />
          </PostHogFeature>
          {routes}
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </StyledEngineProvider>
  );
}

export default App;
