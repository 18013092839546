import {
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

function InviteFriends({ friends }) {
  const finalFriends = friends.slice(0, 8);
  let index = 8;
  const extraFriends =
    (friends?.length || 0) - (finalFriends?.length || 0) || 0;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderAddCircles = () => {
    const addCircles = [];
    const maxAvatars = isMobile ? 6 : 7;
    let addCirclesIndex =
      finalFriends?.length > maxAvatars
        ? -1
        : finalFriends?.length > 4
        ? 1
        : 4 - finalFriends.length;
    while (addCirclesIndex > 0) {
      addCirclesIndex -= 1;
      addCircles.push(
        <Box
          key={addCirclesIndex}
          sx={{
            height: { xs: '60px', sm: '120px', md: '150px' },
            width: { xs: '60px', sm: '120px', md: '150px' },
            border: '5px dashed #000',
            borderWidth: { xs: '3px', sm: '4px', md: '5px' },
            borderRadius: '50%',
            borderColor: '#D9D9D9',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: { xs: '-20px', sm: '-30px', md: '-40px' },
            backgroundColor: { xs: '#f4f4f4', sm: 'white' },
            zIndex: addCirclesIndex,
          }}>
          <Typography
            color="#D9D9D9"
            fontWeight="200"
            sx={{ fontSize: { xs: '40px', sm: '60px', md: '80px' } }}>
            +
          </Typography>
        </Box>
      );
    }
    return addCircles;
  };

  const backgroundColors = [
    ['#FFF1E0', '#ED702E'],
    ['#DFEDFF', '#1877F2'],
    ['#FFDEDC', '#E5493D'],
    ['#F1E3FF', '#B07DE4'],
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        ...(finalFriends?.length < 5 ? { height: '100%' } : {}),
      }}>
      {finalFriends.map((friend, idx) => {
        index -= 1;
        const shouldRenderExtraValue =
          idx === (finalFriends?.length || 0) - 1 && extraFriends > 0;
        return (
          <Avatar
            src={friend?.profilePicture}
            sx={{
              height: { xs: '60px', sm: '130px', md: '150px' },
              width: { xs: '60px', sm: '130px', md: '150px' },
              marginLeft: { xs: '-20px', sm: '-20px', md: '-40px' },
              zIndex: index,
              fontSize: { md: 48, sm: 32, xs: 24 },
              color: shouldRenderExtraValue
                ? '#8A8A8A'
                : backgroundColors[idx % 4][1],
              backgroundColor: shouldRenderExtraValue
                ? '#F4F4F4'
                : backgroundColors[idx % 4][0],
              borderWidth: { md: 4, sm: 3, xs: 2 },
              borderStyle: 'solid',
              borderColor: shouldRenderExtraValue
                ? '#8A8A8A'
                : backgroundColors[idx % 4][1],
            }}>
            {shouldRenderExtraValue
              ? `+${extraFriends}`
              : friend?.firstName?.length > 0 &&
                friend?.firstName?.slice(0, 2)?.toUpperCase()}
          </Avatar>
        );
      })}

      {renderAddCircles()}
    </Box>
  );
}

export default InviteFriends;
