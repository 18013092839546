import React from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Tooltip } from '@mui/material';

const avatarColors = {
  background: ['#FFD9D9', '#D9FAFF', '#E2FFD9'],
  border: ['#F28B87', '#67DAEC', '#8DC67F'],
  text: ['#E5493D', '#1592C2', '#3AA031'],
};

const useStyles = (index, showBorder, fontSize, iconSize = 24) =>
  makeStyles(() => ({
    root: {
      backgroundColor: avatarColors.background[index % 3],
      border: !showBorder ? 0 : `1px solid ${avatarColors.border[index % 3]}`,
      height: iconSize,
      width: iconSize,
      fontSize,
      fontWeight: 600,
      color: avatarColors.text[index % 3],
    },
  }))();

function UserAvatar({
  user,
  index,
  avatarProps = {},
  CustomContent,
  showBorder = true,
  fontSize = '12px',
  iconSize = '36px',
  showNameOnHover = false,
}) {
  const classes = useStyles(
    index,
    !user?.profilePicture && showBorder,
    fontSize,
    iconSize
  );
  const initials =
    (user.firstName ? user.firstName[0].toUpperCase() : '') +
    (user.lastName ? user.lastName[0].toUpperCase() : '');

  const avatar = (
    <Avatar
      sx={{
        fontSize,
      }}
      className={classes.root}
      alt={`${user?.firstName} ${user?.lastName}`}
      src={user?.profilePicture}
      {...avatarProps}>
      {(CustomContent && (
        <CustomContent pathFill={avatarColors.text[index % 3]} />
      )) ||
        initials}
    </Avatar>
  );

  if (showNameOnHover) {
    return (
      <Tooltip title={`${user?.firstName} ${user?.lastName}`} arrow>
        {avatar}
      </Tooltip>
    );
  }

  return avatar;
}

export default UserAvatar;
