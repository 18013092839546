import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import {
  getFirestore,
  connectFirestoreEmulator,
  initializeFirestore,
} from 'firebase/firestore';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';

import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// Same value for development and local
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DBURL,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_CONFIG_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENTID,
};

// use emulator for auth too?
const useAuthEmulator = Boolean(Number(process.env.REACT_APP_AUTH_EMULATOR));

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// workaround with issues regarding bad connection
initializeFirestore(firebaseApp, {
  experimentalForceLongPolling: true,
});

export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const functions = getFunctions(firebaseApp);
export const database = getDatabase(firebaseApp);
if (useAuthEmulator) {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
  connectFunctionsEmulator(functions, 'localhost', 5006);
  connectDatabaseEmulator(database, 'localhost', 9000);
}

// firebase.analytics();
export default {
  firebaseConfig,
};
