import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import graphqlClient from '../../graphql/index';
import QUERY from '../../graphql/queries';
import getAsyncThunk from '../helpers';
import CONFIG from '../../components/config';
import { EVENTS, phTrackEvent } from '../../analytics';
import getCfConnector from '../../components/cfConnector';
import { createImport, deleteImportItem } from './Files';

const initialState = {
  firebaseUser: null,
  error: null,
  success: null,
  accountDeleted: false,
  loading: false,
  userData: {
    user: null,
    status: 'IDLE',
    error: false,
    errorPayload: null,
    subscription: null,
  },
};

export const getUser = createAsyncThunk(
  'USER/getUser',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data, error } = await graphqlClient.query({
        query: QUERY.GET_USER,
        variables: { id },
        fetchPolicy: 'no-cache',
      });
      if (error) throw new Error(error.message);
      return data.getUser;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserSubscriptions = createAsyncThunk(
  'USER/getUserSubscriptions',
  async ({ userEmail }, { rejectWithValue }) => {
    try {
      const subscription = (
        await (
          await getCfConnector()
        ).get(`${CONFIG.paymentsAPI}/get-subscriptions?email=${userEmail}`)
      ).data;
      return subscription;
    } catch (e) {
      return rejectWithValue('Failed');
    }
  }
);

export const updateUser = getAsyncThunk('USER/updateUser', QUERY.UPDATE_USER);

export const deleteUser = getAsyncThunk('USER/deleteUser', QUERY.DELETE_USER);

export const getUserForwardedFiles = getAsyncThunk(
  'USER/getUserForwardedFiles',
  QUERY.GET_USER_FORWARDED_FILES
);

const AuthSlice = createSlice({
  name: 'AUTH',
  initialState,
  reducers: {
    setFirebaseUser: (state, action) => {
      state.firebaseUser = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setAccountDeleted: (state, action) => {
      state.accountDeleted = action.payload;
    },
    updateUser: (state, { payload }) => {
      state.userData.user = { ...state.userData.user, ...payload };
    },
    setAuthLoading: (state, { payload }) => {
      state.loading = payload;
    },
    reset: () => initialState,
    updateForwardedFileImportItemAttachedToItemId: (state, { payload }) => {
      const { fileId, itemIndex, attachedToItemId } = payload;
      if (
        state.userData &&
        state.userData.user &&
        state.userData.user.forwardedFiles.length
      ) {
        const fileIndex = state.userData.user.forwardedFiles.findIndex(
          (f) => f.id === fileId
        );
        if (state.userData.user.forwardedFiles[fileIndex]) {
          state.userData.user.forwardedFiles[fileIndex].import.items[
            itemIndex
          ].attachedToItem = attachedToItemId;
        }
      }
    },
  },
  extraReducers: {
    [getUserSubscriptions.fulfilled]: (state, { payload }) => {
      state.userData.subscription = payload;
    },
    [getUser.pending]: (state) => {
      state.userData.status = 'LOADING';
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.userData.status = 'SUCCESS';
      state.userData.user = payload;
    },
    [getUser.rejected]: (state, { payload }) => {
      state.userData.status = 'IDLE';
      state.userData.error = true;
      state.userData.errorPayload = payload;
    },
    [updateUser.fulfilled]: (state, { meta }) => {
      const { variables } = meta.arg;
      phTrackEvent({
        event: EVENTS.PROFILE.PROFILE_UPDATE,
      });
      state.userData.user = { ...state.userData.user, ...variables };
    },
    [getUserForwardedFiles.fulfilled]: (state, { payload }) => {
      state.userData.user.forwardedFiles = payload?.getUser?.forwardedFiles;
    },
    [createImport.fulfilled]: (state, { payload, meta }) => {
      const fileId = meta.arg.variables?.import?.fileId;
      const importData = payload?.createImport;
      if (
        state.userData.user.forwardedFiles &&
        state.userData.user.forwardedFiles[fileId]
      ) {
        state.userData.user.forwardedFiles[fileId] = {
          ...state.userData.user.forwardedFiles[fileId],
          import: importData,
        };
      }
    },
    [deleteImportItem.fulfilled]: (state, { payload, meta }) => {
      const { fileId, status } = payload?.deleteImportItem || {};
      console.log('status', { status, fileId });
      const { itemIndex } = meta.arg.variables;

      if (state.userData?.user?.forwardedFiles?.length) {
        const file = state.userData.user.forwardedFiles.find(
          (f) => f.id === fileId
        );
        if (file?.import?.items?.length) {
          file.import.items.splice(itemIndex, 1);
          file.import.status = status || file.import.status;
        }
      }
    },
  },
});

export const AuthActions = AuthSlice.actions;
export const AuthReducer = AuthSlice.reducer;
