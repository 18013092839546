import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import classList from '../../../classList';
import { Printer } from '../../Icon';

function ExportPDFButton({ handleExport, pdfLoading }) {
  return (
    <Tooltip title="Export Trip" arrow placement="top">
      <IconButton
        disabled={pdfLoading}
        onClick={handleExport}
        className={classList.exportPdfButton}
        disableTouchRipple
        disableFocusRipple
        sx={{
          position: 'relative',
          '&:hover': {
            backgroundColor: '#D9D9D9',
            borderRadius: '3px',
          },
        }}
        id="export-trip-button">
        <Printer style={{ opacity: pdfLoading ? 0.2 : 1 }} />
        {pdfLoading && (
          <Box sx={{ position: 'absolute', right: 12, top: 5, opacity: 1 }}>
            <CircularProgress size={12} />
          </Box>
        )}
      </IconButton>
    </Tooltip>
  );
}

export default ExportPDFButton;
