import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  updateProfile,
  updateEmail,
  fetchSignInMethodsForEmail,
  isSignInWithEmailLink,
  updateCurrentUser,
  confirmPasswordReset,
  signInWithEmailLink,
  updatePassword,
  signInWithCustomToken,
  OAuthProvider,
} from 'firebase/auth';
import { auth } from './FirebaseIndex';
import { EVENTS, phTrackEvent } from '../analytics';
import store from '../redux/store';
import actions from '../redux/actions';
import functions from './functions';

const errorMessages = {
  'auth/wrong-password': 'The password you have entered is incorrect.',
  'auth/user-not-found':
    "Well this is awkward... there doesn't seem to be an account registered with that email. Try signing up / signing in with a different account.",
  'auth/email-already-in-use':
    'Wow!😍 We love a keener, but you already have an account with that email. Go ahead and sign-in with that.',
  'auth/network-request-failed':
    "Well this sucks; looks like there's a problem with your internet connection. Refresh the page and try again.",
  'auth/too-many-requests':
    "Woahh slow down there hot stuff 🔥, our system can't handle that many login attempts so quickly. Wait a bit and try again.",
  'auth/invalid-email': 'The email address you have entered is not valid.',
  'auth/expired-action-code': 'Your password reset code has expired.',
  'auth/invalid-action-code': 'Sorry Invalid auth code',
  'auth/user-disabled': 'This action has been disabled.',
  'auth/weak-password': 'Your Passowrd is week',

  default: 'Something went wrong, please try again after some time',
};

const getErrorMessage = (errorCode) =>
  Object.prototype.hasOwnProperty.call(errorMessages, errorCode)
    ? errorMessages[errorCode]
    : errorMessages.default;

const authMethods = {
  sendVerificationEmail: (email) =>
    functions.sendVerificationEmail({
      email,
      actionCodeSettings: {
        url: `${window.location.origin}/verify-email`,
      },
    }),
  signup: async (email, password, firstName, lastName, handleError) => {
    if (email) {
      try {
        const displayName = `${firstName} ${lastName}`;

        // create user by firebase-auth
        const { user } = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );

        // update firebase profile with displayname
        await updateProfile(user, {
          displayName,
        });

        await updateCurrentUser(user, {
          emailVerified: true,
        });

        phTrackEvent({
          event: EVENTS.AUTH.ACCOUNT_CREATE,
          meta: {
            account_creation_method: 'email',
          },
        });
      } catch (err) {
        handleError(getErrorMessage(err.code));
      }
    }
  },
  signin: async (email, password, handleError) => {
    if (email) {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        phTrackEvent({
          event: EVENTS.AUTH.SIGNIN_END,
          meta: {
            login_method: 'email',
          },
        });
      } catch (err) {
        handleError(getErrorMessage(err.code));
      }
    }
  },
  providerSignin: async (handleError, providerType = 'Facebook') => {
    const provider =
      providerType === 'Facebook'
        ? new FacebookAuthProvider()
        : providerType === 'Apple'
        ? new OAuthProvider('apple.com')
        : new GoogleAuthProvider();
    provider.addScope('email');

    try {
      // getting user details from provider signin
      const credentials = await signInWithPopup(auth, provider);
      const details = getAdditionalUserInfo(credentials);

      const { user } = credentials;

      // if a new user, create account.
      if (details.isNewUser) {
        if (providerType === 'Facebook') {
          authMethods.sendVerificationEmail(user.email);
          const firstName = user?.displayName?.split(' ')[0];
          window.localStorage.setItem('firstName', firstName);
        }
        phTrackEvent({
          event: EVENTS.AUTH.ACCOUNT_CREATE,
          meta: {
            account_creation_method: providerType,
          },
        });
      } else {
        phTrackEvent({
          event: EVENTS.AUTH.SIGNIN_END,
          meta: {
            login_method: providerType,
          },
        });
      }
    } catch (err) {
      handleError(getErrorMessage(err.code));
    }
  },
  signout: (handleError) => {
    signOut(auth)
      .then(() => {
        store.dispatch(actions.Auth.reset());
        store.dispatch(actions.Trips.resetTrip());
      })
      .catch((err) => {
        handleError(getErrorMessage(err.code));
      });
  },
  passwordRecovery: async (email, handleError) => {
    const actionCodeSettings = {
      url: `${window.location.origin}/auth`,
      handleCodeInApp: false,
    };

    const result = await functions.sendPasswordResetEmail({
      email,
      actionCodeSettings,
    });

    if (result.data) {
      handleError('');
      window.localStorage.setItem('emailForPasswordReset', email);
      return true;
    }
    return false;
  },
  addEmail: async (email, handleError) => {
    return updateEmail(auth.currentUser, email)
      .then(() => true)
      .catch((err) => {
        handleError(getErrorMessage(err.code));
      });
  },
  fetchSignInMethodsForEmail: async (email, handleError) => {
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);
      return methods;
    } catch (err) {
      handleError(getErrorMessage(err.code));
      return [];
    }
  },
  sendSignInLinkToEmail: async (email, handleError) => {
    const actionCodeSettings = {
      url: `${window.location.origin}/auth?email=${email}`,
      // This must be true.
      handleCodeInApp: true,
      // dynamicLinkDomain: `${window.location.origin}/verify-email`,
    };
    try {
      await functions.sendLoginLinkEmail({ email, actionCodeSettings });
      window.localStorage.setItem('emailForSignIn', email);
    } catch (error) {
      handleError(getErrorMessage(error.code));
    }
  },
  isSignInWithEmailLinkLocal: async (handleError) => {
    try {
      return isSignInWithEmailLink(auth, window.location.href);
    } catch (error) {
      handleError(getErrorMessage(error.code));
    }
    return null;
  },
  handleSignInWithEmailLink: async (
    email,
    password,
    firstName,
    lastName,
    signInSession,
    handleError
  ) => {
    try {
      const { user } = await signInWithEmailLink(auth, email, signInSession);

      if (user) {
        // Set the default display name
        const userDisplayName = `${firstName} ${lastName}`;

        // Update the user profile with the display name
        await updateProfile(user, {
          displayName: userDisplayName,
        });

        await updatePassword(user, password);
        phTrackEvent({
          event: EVENTS.AUTH.ACCOUNT_CREATE,
          meta: {
            account_creation_method: 'email',
          },
        });
      }
      window.localStorage.removeItem('emailForSignIn');
    } catch (error) {
      handleError(getErrorMessage(error.code));
    }
  },
  confirmPasswordReset: async (oobCode, newPassword, handleError) => {
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
    } catch (error) {
      handleError(getErrorMessage(error.code));
    }
  },
  signInWithToken: async (token, handleError, isNewUser) => {
    try {
      const { user } = await signInWithCustomToken(auth, token);

      if (isNewUser) {
        const providerType =
          user?.providerData?.map((provider) => {
            if (provider.providerId === 'google.com') {
              return 'Google';
            }
            if (provider.providerId === 'facebook.com') {
              return 'Facebook';
            }
            return 'email';
          }) || null;
        phTrackEvent({
          event: EVENTS.AUTH.ACCOUNT_CREATE,
          meta: {
            account_creation_method: providerType,
            account_creation_source: 'landing-page-signup',
          },
        });
      }
    } catch (error) {
      handleError(getErrorMessage(error.code));
    }
  },
};

export default authMethods;
