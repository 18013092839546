import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AddCircleOutline } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import { getUser, updateUser } from '../../redux/slices/Auth';
import TripsContainer from '../molecules/TripsContainer';
import TripCard from '../molecules/Card/TripCard/index';
import CreateTripCard from '../molecules/Card/CreateTripCard/index';
import LoadingLayout from '../templates/LoadingLayout';
import AppLayout from '../templates/AppLayout';
import FLAGS from '../../featureFlags';
import {
  getTrips,
  createTrip,
  deleteTrip,
  addExampleTrip,
  deleteTripInvitation,
} from '../../redux/slices/Trips';

import NewTripModal from '../molecules/Modal/NewTripModal';
import classList from '../classList';
import InitialModal from '../organisms/CreateATripFlow/InitialModal';
import { EVENTS, phTrackEvent } from '../../analytics';
import TravelStats from '../molecules/TravelStats';
import { getToday } from '../../utils';
import { BookingsActions } from '../../redux/slices/Bookings';

const segregateTrips = (trips) => {
  /*
    This function segregates the trips into upcoming and past trips.
    - Trips with no ending date, show at the end of "upcoming trips".
    - Trips with ending date before today, show in past trips.
    - Trips with ending date today or after, show in upcoming trips.
  */

  const upcoming = [];
  const past = [];
  const tripsWithoutDates = [];
  const today = getToday();

  trips?.forEach((trip) => {
    if (!trip.endDate) {
      tripsWithoutDates.push(trip);
    } else if (Date.parse(trip.endDate) < today) {
      past.push(trip);
    } else {
      upcoming.push(trip);
    }
  });

  const sortBasedOnStartDate = (a, b) => {
    if (Date.parse(a?.startDate) < Date.parse(b?.startDate)) return -1;
    return 1;
  };

  past.sort(sortBasedOnStartDate);
  upcoming.sort(sortBasedOnStartDate);
  upcoming.push(...tripsWithoutDates);

  return { upcomingTrips: upcoming, pastTrips: past };
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '56px',
    padding: '0 calc(100vw * 204 / 1440)',
    [theme.breakpoints.down('sm')]: {
      padding: '0 calc(100vw * 100 / 1440)',
      maxWidth: '550px',
      margin: '0 auto',
    },
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    scrollbarWidth: 'none',
    maxWidth: 'initial',
  },
  exampleTripContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'calc(100vh - 60px)',
    padding: '0 calc(100vw * 204 / 1440)',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      padding: '0 26%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 calc(100vw * 100 / 1440)',
      maxWidth: '550px',
      margin: '0 auto',
    },
  },
  card: {
    maxWidth: 400,
    maxHeight: 400,
    boxShadow: 'none',
    padding: '.5em',
  },
  media: {
    height: '13vw',
    boxShadow: theme.shadows[3],
    borderRadius: '12px',
    backgroundSize: '150%',
  },
  content: {
    textAlign: 'left',
  },
  addTripIcon: {
    width: '16px',
    height: '16px',
  },
  tripPageLayout: {
    [theme.breakpoints.down('sm')]: {
      margin: '16px 0 72px 0',
    },
  },
}));

function ExampleTrip({ onClick }) {
  const tripFutureDistance = 7;
  const tripDuration = 19;
  const startDate = new Date();
  startDate.setDate(startDate.getDate() + tripFutureDistance);
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + tripDuration + tripFutureDistance);
  return (
    <Box>
      <TripCard
        short={false}
        title="Sample Trip - UK 2025"
        startDate={startDate}
        endDate={endDate}
        coverImage="https://images.unsplash.com/photo-1454537468202-b7ff71d51c2e"
        sharedUsers={[]}
        exampleTrip
        onClick={onClick}
      />
    </Box>
  );
}

// Prime component that will be exported from this page.
function Trips() {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.Auth.firebaseUser.uid);
  const userObj = useSelector((state) => state.Auth.userData);
  const tripsObj = useSelector((state) => state.Trips);
  const bookingObj = useSelector((state) => state.Bookings);
  const { labDisabled, welcomeScreen } = userObj.user;
  const [tripPageLoading, setTripPageLoading] = useState(false);
  const [newTripModalOpen, setNewTripModalOpen] = useState(false);
  const [createATripFlowModalOpen, setCreateATripFlowOpen] = useState(false);
  const isQuickStartEnabled = !labDisabled;
  const isCreateTripFlowEnabled = useFeatureFlagEnabled(FLAGS.CREATE_TRIP_FLOW);

  /* Feature flag start */
  const showPwywModal = useFeatureFlagEnabled(FLAGS.PWYW);
  /* Feature flag end */

  // TODO: Call getTrips only once per user-session
  useEffect(() => {
    if (userObj.status === 'IDLE') {
      dispatch(getUser({ id: userId }));
    }
    if (tripsObj.status === 'IDLE') {
      dispatch(getTrips(userId));
    }
  }, []);

  useEffect(() => {
    if (isCreateTripFlowEnabled && !welcomeScreen) setCreateATripFlowOpen(true);
  }, [userObj, isCreateTripFlowEnabled, welcomeScreen]);
  const [hasTrips, setTrips] = useState(true);
  const [segTrips, setSegTrips] = useState({
    upcomingTrips: [],
    pastTrips: [],
  });
  const classes = useStyles();
  const navigate = useNavigate();

  const handleCreateTrip = async (quickstart, createTripFlow = false) => {
    setTripPageLoading(true);
    const trip = await dispatch(
      createTrip({
        trip: {
          title: '',
          coverImage: '',
          owner: userId,
        },
      })
    ).catch((e) => {
      setTripPageLoading(false);
      return e.message;
    });
    setTripPageLoading(false);
    const tripId = trip?.payload?.trip?.id;
    if (bookingObj && !bookingObj[tripId]) {
      dispatch(
        BookingsActions.initializeTripState({
          tripId,
          startDate: tripsObj[tripId]?.startDate,
          endDate: tripsObj[tripId]?.endDate,
        })
      );
    }
    if (!welcomeScreen) {
      dispatch(
        updateUser({
          variables: {
            id: userId,
            welcomeScreen: true,
            labDisabled: !isCreateTripFlowEnabled,
          },
        })
      );
    }
    if (tripId) {
      if (createTripFlow || isCreateTripFlowEnabled) {
        return navigate(`/create-a-trip/${tripId}`, {
          state: { newTrip: true },
        });
      }
      navigate(
        `/trips/${tripId}/planner${quickstart ? '?quickstart=true' : ''}`,
        {
          state: { newTrip: true },
        }
      );
    }
    return null;
  };

  const handleAddExampleTrip = async () => {
    setTripPageLoading(true);
    await dispatch(
      addExampleTrip({
        variables: { userId },
      })
    )
      .then((result) => {
        dispatch(
          updateUser({
            variables: {
              id: userId,
              welcomeScreen: true,
              labDisabled: !isCreateTripFlowEnabled,
            },
          })
        );

        setTripPageLoading(false);
        const tripId = result?.payload?.addExampleTrip;
        if (!tripId) return;

        navigate(`/trips/${tripId}/planner?o=sample-trip`, {
          state: { newTrip: true },
        });
      })
      .catch((e) => {
        setTripPageLoading(false);
        return e.message;
      });
  };

  const handleDeleteTrip = async (tripId) => {
    await dispatch(deleteTrip(tripId));
    dispatch(BookingsActions.deleteTrip({ tripId }));
  };

  useEffect(() => {
    if (tripsObj.trips) {
      setSegTrips(segregateTrips(Object.values(tripsObj.trips)));
      setTrips(true);
    }
  }, [userObj, tripsObj]);
  const invitations = useSelector((state) => state.Trips.invitations).filter(
    (invitation) => invitation?.tripId
  );
  useEffect(() => {
    (async () => {
      if (invitations.length > 0) {
        const { tripId, id } = invitations[0];

        await dispatch(
          deleteTripInvitation({
            variables: { tripId, id },
          })
        );

        if (tripId) {
          navigate(`/join/${tripId}`);
        }
      }
    })();
  }, [invitations]);

  if (
    userObj.status === 'LOADING' ||
    tripsObj.status === 'LOADING' ||
    tripPageLoading
  ) {
    return <LoadingLayout />;
  }
  if (userObj.error) {
    return <Typography>Error Occured! Contact Admin.</Typography>;
  }

  const handleCreateTripButtonClick = () => {
    phTrackEvent({
      event: EVENTS.TRIPS_DASHBOARD.TRIP_CREATE_START,
      meta: {
        is_first_trip: Object.keys(tripsObj.trips || {}).length === 0,
      },
    });
    if (isCreateTripFlowEnabled) {
      return handleCreateTrip(false, true);
    }
    if (isQuickStartEnabled) {
      setNewTripModalOpen(true);
    } else handleCreateTrip();
    return null;
  };
  return (
    <AppLayout>
      <Helmet>
        <title>Trips | Pilot Trip Planner</title>
      </Helmet>
      <div className={classes.tripPageLayout}>
        {Object.keys(tripsObj.trips).length === 0 ? (
          <Box className={classes.exampleTripContainer}>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: 600,
                lineHeight: '40px',
                paddingBottom: '16px',
                textAlign: 'center',
              }}>
              Let’s start trip planning!
            </Typography>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: '24px',
                paddingBottom: '57px',
                textAlign: 'center',
              }}>
              We’ve pre-built an example trip to the UK you can use to learn how
              Pilot works. Or if you’re ready to start planning you can do so by
              clicking on “Create a trip”
            </Typography>
            <Grid container spacing={3.25}>
              <Grid item xs={12} md={6} sm={6}>
                <ExampleTrip userId={userId} onClick={handleAddExampleTrip} />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <CreateTripCard onClick={handleCreateTripButtonClick} />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <div className={classes.container}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <Grid item>
                <Typography variant="h2Sub">Upcoming Trips</Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleCreateTripButtonClick}
                  startIcon={
                    <AddCircleOutline className={classes.addTripIcon} />
                  }
                  className={classList.createTripButton}>
                  Create a trip
                </Button>
              </Grid>
            </Grid>
            {showPwywModal && <TravelStats />}
            <TripsContainer
              deleteTripCallback={handleDeleteTrip}
              trips={hasTrips && segTrips.upcomingTrips}
              horizontalFit={4}
              tripType="upcoming"
              showCreateTripCard={
                !segTrips.upcomingTrips || segTrips.upcomingTrips?.length < 3
              }
              handleCreateTrip={handleCreateTripButtonClick}
              className={classList.upcomingTripsContainer}
            />
            <Typography variant="h3Sub">Past Trips</Typography>
            <TripsContainer
              deleteTripCallback={handleDeleteTrip}
              trips={hasTrips && segTrips.pastTrips}
              defaultMessage="Oh no! You have no past trips 😮"
              className={classList.pastTripsContainer}
              tripType="past"
            />
          </div>
        )}

        <NewTripModal
          open={newTripModalOpen}
          handleClose={() => setNewTripModalOpen(false)}
          handleCreateTrip={handleCreateTrip}
        />
        <InitialModal
          open={createATripFlowModalOpen}
          handleClose={() => setCreateATripFlowOpen(false)}
          handleCreateTrip={handleCreateTrip}
          handleAddExampleTrip={handleAddExampleTrip}
        />
      </div>
    </AppLayout>
  );
}

export default Trips;
