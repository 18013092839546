import { Alert, Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: '#E5493D',
  color: 'white',
  '& .MuiAlert-message': {
    flex: 1,
    fontSize: '1rem',
    padding: theme.spacing(0.5, 0),
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiAlert-action': {
    padding: theme.spacing(0.5, 1),
    alignItems: 'center',
  },
  borderRadius: theme.spacing(1),
  boxShadow:
    '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1), 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  alignItems: 'center',
  minHeight: '48px',
}));

const RetryButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'white',
  color: '#4E4E4E',
  '&:hover': {
    backgroundColor: '#f8f9fa',
    color: '#4E4E4E',
  },
  textTransform: 'none',
  fontWeight: 500,
  padding: theme.spacing(0.5, 2),
}));

export default function AlertAction({
  children,
  actionText,
  action,
  bgColor = '#E5493D',
  textColor = 'white',
}) {
  return (
    <Box sx={{ maxWidth: 'sm', margin: 'auto', p: 2 }}>
      <StyledAlert
        severity="error"
        sx={{ backgroundColor: bgColor, color: textColor }}
        icon={false}
        action={
          <RetryButton onClick={action} variant="contained" disableElevation>
            {actionText}
          </RetryButton>
        }>
        <Typography variant="body1">{children}</Typography>
      </StyledAlert>
    </Box>
  );
}
