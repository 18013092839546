import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Grid } from '@mui/material';
import { format } from 'date-fns';
import { airports } from '@pilotplans/aviation-data';
import { AirplanemodeActiveRounded, TrainRounded } from '@mui/icons-material';
import classList from '../../../classList';
import { getAirlineFromFlightNumber, parseISODate } from '../../../../utils';
import { SemiFoldedMapIcon } from '../../../atoms/Icon';
import TrasportationModifier from './TransportationModifier';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '4.5%',
  },
  root: {
    flex: 1,
    color: '#000000',
    backgroundColor: '#F4F4F4',
    border: '1px solid #DEDDDD',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    marginLeft: 4,
    flexDirection: 'column',
    '&:hover': {
      border: '1px solid #A7A7A7',
      color: '#000000',
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  imageContainer: {
    backgroundColor: '#FFFFFF',
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 60,
    maxHeight: 60,
    objectFit: 'contain',
  },
  flightNumber: {
    fontSize: '0.8rem',
    marginLeft: '4%',
    display: 'inline-flex',
    color: '#A7A7A7',
  },
  city: {
    fontSize: '1.2rem',
  },
  iata: {
    fontSize: '1rem',
    color: '#A7A7A7',
  },
  actionContainer: {
    position: 'absolute',
    margin: 'auto',
    top: 12,
    left: 'calc(-3.6rem - 15px)',
    minWidth: 'calc(3.6rem + 15px)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionIcon: {
    fontSize: '1.2rem',
    color: 'rgba(138, 138, 138, 1)',
    marginLeft: 4,
    '&:hover': {
      cursor: 'pointer',
      color: '#474747',
    },
  },
  mainContainer: {
    padding: '2%',
    display: 'flex',
  },
  detailsText: {
    fontSize: '0.7rem',
    color: '#8a8a8a',
  },
  mainText: {
    fontSize: '1.1rem',
  },
  transportIconWrapper: {
    width: '90%',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    position: 'relative',
  },
  transportIconContainer: {
    backgroundImage:
      'linear-gradient(to right, #A7A7A7 60%, rgba(0,0,0,0) 40%)',
    backgroundPosition: 'top',
    backgroundSize: '8px 1px',
    backgroundRepeat: 'repeat-x',
    width: '100%',
    height: 1,
  },
}));

function FlightBookingCard({
  item,
  setItem,
  openBlade: openTransportationPanel,
  setOpenBlade: setOpenTransportationPanel,
}) {
  const classes = useStyles();
  const userObj = useSelector((state) => state.Auth.userData);
  const [transport, setTransport] = useState(item || null);
  const is24HourFormat = userObj.user.is24HourTime;

  const type = transport?.type || 'flight';

  function CollapsedIcon() {
    const iconStyle = {
      backgroundColor: '#FFF',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20%',
    };

    let res;
    switch (type) {
      case 'train':
      case 'bus':
        res = <TrainRounded style={iconStyle} />;
        break;
      case 'other':
        iconStyle.width = '24px';
        res = <SemiFoldedMapIcon style={iconStyle} />;
        break;
      default:
        iconStyle.transform = 'rotate(90deg)';
        res = <AirplanemodeActiveRounded style={iconStyle} />;
    }
    return (
      <div className={classes.transportIconWrapper}>
        <div className={classes.transportIconContainer} />
        <div style={{ position: 'absolute' }}>{res}</div>
      </div>
    );
  }

  const formatDate = (date) => {
    if (!date) return '';
    return format(date, 'MMM dd');
  };

  const formatTime = (date) => {
    if (!date) return '';
    const timeFormat = is24HourFormat ? 'HH:mm' : 'hh:mm aa';
    return format(date, timeFormat);
  };

  return !openTransportationPanel ? (
    <Grid
      item
      fullWidth
      justify="space-around"
      alignItems="center"
      className={`${classes.root} ${classList.transportationBlock}`}
      onClick={(e) => {
        setOpenTransportationPanel(e.currentTarget);
      }}>
      {transport?.details?.map((flight, index) => {
        const fromCity = flight.fromAirport
          ? transport.type === 'flight'
            ? airports
                ?.findWhere({
                  [flight.fromAirport?.length === 3 ? 'iata' : 'icao']:
                    flight.fromAirport,
                })
                ?.get('city')
            : flight.fromAirport
          : 'Departure';
        const toCity = flight.toAirport
          ? transport.type === 'flight'
            ? airports
                ?.findWhere({
                  [flight.toAirport?.length === 3 ? 'iata' : 'icao']:
                    flight.toAirport,
                })
                ?.get('city')
            : flight.toAirport
          : 'Arrival';
        const fromDate = flight?.fromDate
          ? parseISODate(flight?.fromDate, flight?.version)
          : null;
        const toDate = flight?.toDate
          ? parseISODate(flight?.toDate, flight?.version)
          : null;

        const { airlineIcaoCode = null, airline = null } =
          transport.type === 'flight'
            ? getAirlineFromFlightNumber(flight?.flightNumber || '')
            : {};

        return (
          <Grid
            item
            container
            key={index}
            style={{
              borderTop: index === 0 ? '' : '1px solid #DEDDDD',
            }}>
            <Grid item xs={9} className={classes.mainContainer}>
              <Box
                style={{
                  display: 'flex',
                  flexShrink: 1,
                  maxWidth: '40%',
                }}>
                <Grid
                  container
                  item
                  direction="column"
                  style={{ display: 'block' }}>
                  <Typography className={classes.detailsText}>
                    {!fromDate ? (
                      <span style={{ color: 'transparent' }}>.</span>
                    ) : (
                      formatDate(fromDate)
                    )}
                  </Typography>
                  <Typography
                    noWrap
                    className={classes.mainText}
                    sx={{
                      maxWidth: '90px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      '@media (min-width: 400px)': {
                        maxWidth: '150px',
                      },
                      '@media (min-width: 750px)': {
                        maxWidth: '100px',
                      },
                      '@media (min-width: 1300px)': {
                        maxWidth: '150px',
                      },
                      '@media (min-width: 1500px)': {
                        maxWidth: '200px',
                      },
                      textAlign: 'center',
                    }}>
                    {fromCity}
                  </Typography>
                  <Typography className={classes.detailsText}>
                    {!fromDate ? (
                      <span style={{ color: 'transparent' }}>hi</span>
                    ) : (
                      formatTime(fromDate)
                    )}
                  </Typography>
                </Grid>
              </Box>
              <div style={{ display: 'flex', flex: 1 }}>
                <Grid container item direction="column" alignItems="center">
                  <Typography className={classes.detailsText}>
                    {/* {!duration ? (
                              <span style={{ color: 'transparent' }}>.</span>
                            ) : (
                              `${duration ? `${duration}` : ''}`
                            )} */}
                  </Typography>
                  <CollapsedIcon />
                  <Typography
                    className={classes.detailsText}
                    sx={{
                      maxWidth: '90px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      '@media (min-width: 400px)': {
                        maxWidth: '150px',
                      },
                      '@media (min-width: 750px)': {
                        maxWidth: '100px',
                      },
                      '@media (min-width: 1300px)': {
                        maxWidth: '150px',
                      },
                      '@media (min-width: 1500px)': {
                        maxWidth: '200px',
                      },
                      textAlign: 'center',
                    }}>
                    {!flight?.flightNumber ? (
                      <span style={{ color: 'transparent' }}>.</span>
                    ) : (
                      flight?.flightNumber
                    )}
                  </Typography>
                </Grid>
              </div>
              <Box
                style={{
                  display: 'flex',
                  flexShrink: 1,
                  maxWidth: '40%',
                }}>
                <Grid
                  container
                  item
                  direction="column"
                  alignItems="flex-end"
                  style={{ display: 'block' }}>
                  <Typography className={classes.detailsText}>
                    {!toDate ? (
                      <span style={{ color: 'transparent' }}>hi</span>
                    ) : (
                      formatDate(toDate)
                    )}
                  </Typography>
                  <Typography
                    noWrap
                    className={classes.mainText}
                    sx={{
                      maxWidth: '90px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      '@media (min-width: 400px)': {
                        maxWidth: '150px',
                      },
                      '@media (min-width: 750px)': {
                        maxWidth: '100px',
                      },
                      '@media (min-width: 1300px)': {
                        maxWidth: '150px',
                      },
                      '@media (min-width: 1500px)': {
                        maxWidth: '200px',
                      },
                      textAlign: 'center',
                    }}>
                    {toCity}
                  </Typography>
                  <Typography className={classes.detailsText}>
                    {!toDate ? (
                      <span style={{ color: 'transparent' }}>.</span>
                    ) : (
                      formatTime(toDate)
                    )}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={3} className={classes.imageContainer}>
              {type === 'flight' ? (
                <img
                  className={classes.image}
                  src={
                    airlineIcaoCode
                      ? `https://storage.googleapis.com/prod-airplane-logos/${airlineIcaoCode}.png`
                      : '/images/flights.svg'
                  }
                  style={{
                    width: airlineIcaoCode ? '100%' : 60,
                  }}
                  onError={(e) => {
                    e.target.src = '/images/flights.svg';
                    e.target.style.width = 60;
                  }}
                  alt={airline || 'flight'}
                />
              ) : (
                <img
                  src="/images/foldedMap.svg"
                  alt="transport"
                  className={classes.image}
                />
              )}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <TrasportationModifier
      open={openTransportationPanel}
      setOpen={setOpenTransportationPanel}
      newFlight={false}
      tripId={transport?.trip}
      id={transport?.id}
      key={transport?.id}
      defaultFlightProps={transport}
      setTransport={setTransport}
      setItem={setItem}
      type={type}
    />
  );
}

export default FlightBookingCard;
