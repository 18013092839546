import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { Link } from '@mui/icons-material';
import { getDetails } from 'use-places-autocomplete';
import classList from '../../../classList';
import { switchLink, trackTpLink } from '../../../../tp-switcher';
import PlacesImage from '../../PlacesImage';
import { parseISODate, removeTimezoneOffset } from '../../../../utils';
import InlineBlade from '../../InlineBlade';
import { updateAccommodation } from '../../../../redux/slices/Accommodation';

const useStyles = makeStyles(({ typography }) => ({
  root: {
    color: '#000000',
    backgroundColor: '#F4FCFD',
    border: '1px solid #DEDDDD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    overflow: 'hidden',
    '&:hover': {
      border: '1px solid #A7A7A7',
      color: '#000000',
      backgroundColor: '#F4FCFD',
      cursor: 'pointer',
    },
  },
  actionIcon: {
    fontSize: '1.2rem',
    color: '#8A8A8A',
    opacity: '1',
    marginLeft: 4,
    '&:hover': {
      cursor: 'pointer',
      color: '#474747',
    },
  },
  mainContainer: {
    padding: '8px 0% 8px 8px',
  },
  description: {
    ...typography.h5,
    marginTop: 4,
    fontSize: 14,
    color: '#8A8A8A',
  },
  imageContainer: {
    backgroundColor: '#FFFFFF',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: 60,
  },
  link: {
    color: '#A7A7A7',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    textDecorationLine: 'underline',
  },
  linkIcon: {
    color: '#A7A7A7',
    width: '20px',
    padding: '0 0.25em',
  },
}));

function AccommodationBlockImport({
  item,
  setItem,
  openBlade: activeAccommodation,
  setOpenBlade,
}) {
  const placeId = item?.placeId;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [hotel, setHotel] = useState(item || null);
  const [attachedFiles, setAttachedFiles] = useState(hotel?.files || []);
  const [image, setImage] = useState('/images/hotel.svg');

  useEffect(() => {
    if (placeId) {
      getDetails({
        placeId,
        fields: ['photos'],
      })
        .then((details) => {
          setImage(details?.photos?.length > 0 && details.photos[0].getUrl());
        })
        .catch((err) => {
          console.error('Error getting place details:', err);
        });
    }
  }, [placeId]);

  const formatDate = (date) => {
    return date ? format(parseISODate(date, true), 'MMM do') : '';
  };

  const description =
    hotel.checkInDate && hotel.checkOutDate
      ? `${formatDate(hotel.checkInDate)} - ${formatDate(hotel.checkOutDate)}`
      : '';
  const link = hotel.link || null;
  const isBooking = hotel?.isBooking;

  const updateAccomodationAttributes = (attributes) => {
    const updatedAttributes = { ...attributes };
    const currentHotel = { ...hotel };

    try {
      Object.keys(updatedAttributes).forEach((attr) => {
        if (currentHotel[attr] === updatedAttributes[attr]) {
          delete updatedAttributes[attr];
        }
      });

      if (
        updatedAttributes?.mapPin &&
        currentHotel?.mapPin &&
        updatedAttributes?.mapPin !== currentHotel?.mapPin
      ) {
        updatedAttributes.oldMapPin = currentHotel.mapPin;
      }
    } catch (err) {
      console.error('Error updating attributes:', err);
      return err;
    }

    if (!Object.keys(updatedAttributes).length) return null;

    const updatedHotel = {
      ...hotel,
      ...updatedAttributes,
    };
    setHotel(updatedHotel);
    setItem(updatedHotel);

    return true;
  };

  return !activeAccommodation ? (
    <Grid container direction="row" onClick={() => setOpenBlade(true)}>
      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="center"
        className={`${classes.root} ${classList.item} ${classList.accommodationBlock}`}
        sx={{
          marginLeft: 0,
        }}>
        <Grid item xs={9} className={classes.mainContainer}>
          <Box alignItems="center" flexDirection="column">
            <Typography variant="h4">{hotel.name}</Typography>
            <div className={classes.description}>
              {description}
              {link ? (
                <a
                  className={classes.link}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => trackTpLink(link)}
                  href={switchLink(link) || link}>
                  <Link className={classes.linkIcon} />
                  Link
                </a>
              ) : null}
            </div>
          </Box>
        </Grid>
        <Grid item xs={3} className={classes.imageContainer}>
          {image ? (
            <img src={image} alt="hotel" className={classes.image} />
          ) : (
            <PlacesImage
              fallbackImage="/images/hotel.svg"
              mapPin={hotel?.mapPin}
              className={classes.image}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <InlineBlade
      name={hotel.name}
      hasDate={false}
      hasLink
      hasCost
      hasLocation
      address={hotel.streetAddress}
      notes={hotel.notes}
      open={activeAccommodation}
      setOpen={setOpenBlade}
      isAccomodation
      hasCustomCalendar
      mapPinId={hotel?.mapPin}
      updateLocationCallback={(locationAttributes) => {
        const { mapPin = null, ...location } = locationAttributes;
        updateAccomodationAttributes({
          ...location,
          ...(mapPin ? { mapPin: mapPin.id, mapPinObj: mapPin } : {}),
        });
      }}
      updateLinkCallback={(updatedLink) =>
        updateAccomodationAttributes({ links: updatedLink })
      }
      updateNotesCallback={(notes) => updateAccomodationAttributes({ notes })}
      costVal={{
        amount: hotel.cost,
        per: hotel.costPer,
        currency: hotel.currency,
      }}
      updateCostCallback={({ amount, currency, per }) =>
        updateAccomodationAttributes({
          cost: parseFloat(amount),
          costPer: parseInt(per, 10),
          currency,
        })
      }
      updateNameCallback={(name) => updateAccomodationAttributes({ name })}
      accomodationProps={{
        checkinDate: hotel.checkInDate,
        checkoutDate: hotel.checkOutDate,
        isBooking,
        updateCheckinDate: (date) =>
          updateAccomodationAttributes({
            checkInDate: removeTimezoneOffset(date)?.toISOString(),
          }),
        updateCheckoutDate: (date) =>
          updateAccomodationAttributes({
            checkOutDate: removeTimezoneOffset(date)?.toISOString(),
          }),
      }}
      updateAccommodation={(accommodation) =>
        updateAccomodationAttributes(accommodation)
      }
      fileAttachmentModalProps={{
        attachedToID: '',
        attachedToType: 'Accommodation',
        attachedFiles,
        attachedToText: hotel?.name,
        attachFunc: (newFiles, tripId) => {
          return new Promise((resolve, reject) => {
            dispatch(
              updateAccommodation({
                variables: {
                  id: '',
                  files: newFiles,
                },
                attachedToType: 'Accommodation',
                tripId,
              })
            )
              .then((res) => {
                setAttachedFiles(newFiles);
                resolve(res);
              })
              .catch((err) => reject(err));
          });
        },
      }}
    />
  );
}

export default AccommodationBlockImport;
