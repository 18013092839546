import React, { useState, useRef, useEffect } from 'react';

import {
  Popper,
  List,
  ListItem,
  ClickAwayListener,
  ListItemIcon,
  Divider,
  ListItemText,
  Typography,
  Box,
} from '@mui/material';
// removed LaunchRounded
import {
  ArrowDropDownRounded,
  AddCircleOutlineRounded,
  ChevronRightRounded,
  CheckRounded,
} from '@mui/icons-material';

import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';

import { useMapUtils } from '../../organisms/MapUtils';
import {
  createActivity,
  createNewActivityInLocation,
} from '../../../redux/slices/Activity';
import { createLocation } from '../../../redux/slices/Location';
import { ButtonDefault } from '../../atoms/Button/index';
import { trackEvents, Events } from '../../../intercom';
import { useSounds, SOUNDS } from '../../../sounds';
import { EVENTS, phTrackEvent } from '../../../analytics';
import { useTour } from '../Tour/useTour';
import { stepName } from '../../../assets/onboarding/steps';
import actions from '../../../redux/actions';

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 9121,
    width: 'inherit',
  },
  list: {
    maxHeight: 160,
    overflowY: 'auto',
    backgroundColor: '#FFF',
    border: '2px solid #DEDDDD',
    borderRadius: 4,
    padding: '4px 0px',
    marginTop: 8,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: 12,
    },
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
  },
  listItem: {
    minWidth: 120,
    padding: '4px 12px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      '& .MuiListItemIcon-root': {
        color: '#ED702E !important',
      },
    },
  },
  addedButton: {
    backgroundColor: '#43A047',
    color: '#FFF',
    pointerEvents: 'none',
  },
  listItemText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
}));

/**
 *
 * @param {Boolean} isAdded - condition to toggle between the 'Add to trip' and 'Added' button states
 */
function AddToTripButton({
  tripId,
  placeDetails,
  // info we aren't storing in the backend
  additionalPlaceDetails = {},
  addressComponents,
  id: idSelector,
  className: classSelector,
  source = 'map',
  buttonProps = {},
  handleSelectHeading = null,
  handleSelectLocation = null,
  isAdded = false,
  customAnchor = null,
  CustomButton = null,
  setLoader = () => {},
  initAction = () => {},
  postAction = () => {},
  handleClose = () => {},
  setOpenSearchbar = () => {},
  smartImport = false,
  trigger = null,
  popoverTitle = '',
  newLocationText = 'New Location',
  disableNewLocation = false,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { playSound } = useSounds();
  const dispatch = useDispatch();
  const currentTrip = useSelector((state) => state.Trips.trips[tripId]);
  const { locationPopperOpen } = useSelector((state) => state.View);
  const locationIds = currentTrip?.items
    ?.filter((item) => item.location !== null)
    ?.map((item) => item.location);
  const locations = useSelector((state) => state.Location.locations);
  const thingsToDo = useSelector((state) => state.Section.sections);
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  const [locationAnchor, setLocationAnchor] = useState(null);
  const popperId = open ? 'search-location-popper' : undefined;

  const { createMapPinForPlace, extractAddressComponents } = useMapUtils();
  const { getTour } = useTour();

  const setLocationPopperOpen = (value) =>
    dispatch(actions.View.setLocationPopperOpen(value));
  const handleAddToTripClick = (e) => {
    getTour()
      .onActiveTour([
        'emptyTripFlowWeb',
        'blankTripFlowWeb',
        'sampleTripFlowWeb',
        'quickStartFlowWeb',
      ])
      .onActiveStep(stepName.ADD_TO_TRIP_BUTTON)
      .closeTour();

    e.stopPropagation();
    setAnchorEl(CustomButton ? customAnchor : ref?.current);
    setLocationAnchor(null);
    setOpen(!open);
  };

  useEffect(() => {
    if (trigger && !anchorEl) {
      setAnchorEl(customAnchor);
      setOpen(true);
    }
  }, [trigger]);

  const handleAddLocation = async () => {
    setLoader(true);
    playSound(SOUNDS.inOutClick);
    const mapPinId = await createMapPinForPlace(
      placeDetails,
      'LOCATION',
      additionalPlaceDetails
    );
    await dispatch(
      createLocation({
        variables: {
          name: placeDetails.title,
          tripID: tripId,
          mapPin: mapPinId,
          index: 0,
        },
      })
    );
    setLoader(false);
  };

  const handleAddActivity = async (activityTitle, locationId, sectionId) => {
    setLoader(true);
    const result = await initAction({
      newLocationId: locationId,
      newSectionId: sectionId,
    });
    playSound(SOUNDS.inOutClick);

    // action already done, no need to repeat activity
    if (result === 'NO-ACTION') {
      setLoader(false);
      return;
    }
    if (
      source === 'map' ||
      source === 'recommendations' ||
      source === 'recommendations-details'
    ) {
      phTrackEvent({
        event:
          source === 'map'
            ? EVENTS.PLAN_MAP_CARD.ACTIVITY_ADD
            : source === 'recommendations'
            ? EVENTS.EXPLORE_ACTIVITIES.ACTIVITY_PLAN_ADD
            : EVENTS.EXPLORE_ACTIVITY_DETAIL.ACTIVITY_PLAN_ADD,
      });
    }
    // addMapPin
    const mapPin = await createMapPinForPlace(
      {
        title: activityTitle,
        ...placeDetails,
      },
      'ACTIVITY',
      additionalPlaceDetails
    );
    const address = extractAddressComponents(addressComponents);
    if (window?.heap)
      window?.heap.track('Activity Created', {
        source: 'Map',
      });
    if (sectionId === null) {
      await dispatch(
        createNewActivityInLocation({
          locationId,
          activityTitle,
          mapPin: mapPin?.id,
          ...address,
        })
      );
    } else {
      await dispatch(
        createActivity({
          variables: {
            title: activityTitle,
            mapPin: mapPin?.id,
            thingsToDoId: sectionId,
            index: thingsToDo[sectionId]?.todos?.length,
            ...address,
          },
          sectionId,
          index: thingsToDo[sectionId]?.todos?.length,
        })
      );
    }
    trackEvents(
      source === 'map'
        ? Events.LocationAddedFromMap
        : Events.RecommendationsItemAdded
    );
    await postAction({
      locationId,
      sectionId,
    });
    setLoader(false);
    if (getTour().isActiveStep(stepName.ADD_TO_TRIP_BUTTON)) {
      getTour().goToNextStep().openTour({
        delay: 1000,
      });
    } else {
      getTour().onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO).openTour({
        delay: 1000,
      });
    }
  };

  const handleClickAway = () => {
    setOpen(false);
    setLocationPopperOpen(null);
    handleClose(null);
  };

  const renderLocations = () =>
    locationIds &&
    locationIds.map((locationId, index) => {
      const location = locations[locationId];
      if (!location) return;
      const { id, name } = location;
      const handleLocationHover = (e) => {
        if (handleSelectLocation) {
          return;
        }
        setLocationAnchor(e.currentTarget);

        if (locationPopperOpen === id) return;
        setLocationPopperOpen(id);
        setLocationAnchor(e.currentTarget);
      };
      const sections =
        location?.thingsToDo?.map((thingsToDoId) => thingsToDo[thingsToDoId]) ||
        [];

      // eslint-disable-next-line consistent-return
      return (
        <ListItem
          key={id}
          onClick={() => {
            if (handleSelectLocation) {
              handleClickAway();
              return handleSelectLocation(id, index);
            }
            if (sections.length === 0) {
              handleAddActivity(placeDetails?.title, id, null);
              handleClickAway();
            }
          }}
          id={idSelector || 'add-to-trip-button'}
          onMouseOver={handleLocationHover}
          onMouseLeave={() => {
            if (sections?.length === 0) {
              setLocationPopperOpen(null);
            }
          }}
          className={`${classes.listItem} ${classSelector || ''}`}
          style={{
            // to align the text, 12px(specified) + 2px(alignment spacing) with the 'new location' icon according to the designs
            paddingLeft: 14,
          }}
          alignItems="center">
          <ListItemText
            primary={name}
            primaryTypographyProps={{ fontSize: '14px' }}
            className={classes.listItemText}
          />
          {sections.length > 0 && (
            <>
              <ListItemIcon
                style={{
                  minWidth: 0,
                }}>
                <ChevronRightRounded
                  style={{
                    height: 14,
                    width: 14,
                    ...(locationPopperOpen === id
                      ? {}
                      : { color: 'transparent' }),
                  }}
                />
              </ListItemIcon>
              <Popper
                id={`location-${id}`}
                open={locationPopperOpen === id}
                anchorEl={locationAnchor}
                placement="right"
                style={{
                  width: 'inherit',
                  zIndex: 9122,
                  visibility: !locationAnchor ? 'hidden' : 'visible',
                }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [-4, 8],
                    },
                  },
                ]}>
                <ClickAwayListener onClickAway={() => {}}>
                  <List
                    className={classes.list}
                    style={{
                      maxHeight: 100,
                      maxWidth: 120,
                    }}
                    onMouseLeave={() => {
                      setLocationPopperOpen(null);
                    }}>
                    {sections?.map((section) => {
                      return (
                        <ListItem
                          key={section.id}
                          onClick={() => {
                            if (handleSelectHeading) {
                              handleClickAway();
                              return handleSelectHeading(section?.id);
                            }
                            handleAddActivity(
                              placeDetails?.title,
                              id,
                              section?.id
                            );
                            handleClickAway();
                          }}
                          className={classes.listItem}
                          justify="center"
                          style={{ padding: '2px 12px' }}>
                          <ListItemText
                            primary={
                              section.name === ''
                                ? 'Unnamed Section'
                                : section?.name
                            }
                            primaryTypographyProps={{ fontSize: '14px' }}
                            className={classes.listItemText}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </ClickAwayListener>
              </Popper>
            </>
          )}
        </ListItem>
      );
    });
  return (
    <>
      {CustomButton ? (
        <CustomButton ref={ref} onClick={handleAddToTripClick} />
      ) : (
        <ButtonDefault
          ref={ref}
          disableFocusRipple
          disableRipple
          onClick={handleAddToTripClick}
          endIcon={isAdded ? <CheckRounded /> : <ArrowDropDownRounded />}
          className={isAdded && classes.addedButton}
          {...buttonProps}>
          {isAdded ? 'Added to trip' : 'Add to trip'}
        </ButtonDefault>
      )}
      <Popper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <List className={classes.list}>
            {popoverTitle && (
              <Box ml="8px">
                <Typography fontWeight="500" fontSize="10px" color="#8A8A8A">
                  {popoverTitle}
                </Typography>
              </Box>
            )}
            {!disableNewLocation && (
              <>
                <ListItem
                  key="create-new-location"
                  onClick={async () => {
                    if (smartImport) {
                      setOpenSearchbar(true);
                    } else {
                      await handleAddLocation();
                    }
                    handleClickAway();
                  }}
                  onMouseOver={() => {
                    setLocationPopperOpen('create-new-location');
                  }}
                  className={classes.listItem}
                  style={{
                    whiteSpace: 'nowrap',
                    justifyContent: 'flex-start',
                  }}>
                  <ListItemIcon
                    style={{
                      minWidth: 0,
                      marginRight: 4,
                    }}>
                    <AddCircleOutlineRounded
                      style={{
                        height: 14,
                        width: 14,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={newLocationText}
                    primaryTypographyProps={{
                      fontSize: '14px',
                    }}
                  />
                </ListItem>
                <Divider style={{ marginTop: 4, marginBottom: 4 }} />
              </>
            )}
            {renderLocations()}
          </List>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default AddToTripButton;
