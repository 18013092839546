const FLAGS = {
  APP_SOUNDS: 'app-sounds',
  PWYW: 'pay-what-you-want',
  CREATE_TRIP_FLOW: 'create-trip-flow',
  PILOT_BOOKINGS: 'pilot-bookings',
  UNIVERSAL_ADD_BAR: 'universal-add-bar',
  UNIVERSAL_ADD_BAR_AI_BUTTON: 'universal-add-bar-ai-button',
  UNIVERSAL_ADD_BAR_KEYBOARDSHORTCUTS: 'universal-add-bar-keyboard-shortcuts',
  TRIP_ONBOARDING: 'trip-onboarding',
  SMART_IMPORT: 'smart-import',
};

export default FLAGS;
