import { Divider, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/styles';

function OptionsMenu({
  id,
  options,
  setState,
  state,
  callbacks,
  menuProps = {},
  menuItemProps = {},
  isDividerVisisble = true,
  isMultipleCallback = true,
}) {
  const handleClose = (e, index) => {
    e.stopPropagation();
    if (isMultipleCallback) {
      if (callbacks[index]) callbacks[index]();
    } else {
      callbacks[0](index);
    }
    setState(null);
  };
  const { palette } = useTheme();

  return (
    <Menu
      id={id}
      anchorEl={state}
      keepMounted
      open={Boolean(state)}
      onClose={handleClose}
      sx={{ zIndex: 9000 }}
      {...menuProps}>
      {options.map((option, index) => {
        return (
          <div key={index}>
            <MenuItem
              style={{
                fontWeight: 500,
                color: palette?.text?.light,
              }}
              onClick={(e) => handleClose(e, index)}
              {...menuItemProps}>
              {option}
            </MenuItem>
            {isDividerVisisble &&
              (index === 1 ? null : <Divider style={{ margin: 0 }} />)}
          </div>
        );
      })}
    </Menu>
  );
}

export default OptionsMenu;
