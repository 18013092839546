import { createContext, useState, useContext } from 'react';
import { getDetails } from 'use-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createMapPin, updateMapPin } from '../../redux/slices/Map';
import { getCategoryIdFromTypes } from '../../utils';
import useTour from '../molecules/Tour/useTour';
import { stepName } from '../../assets/onboarding/steps';

export const MapContext = createContext();

export function MapProvider({ children }) {
  // states
  const [place, setPlace] = useState(null);
  const { slug: tripId = null } = useParams();
  const [viewport, setViewport] = useState({
    latitude: 45.50884,
    longitude: -73.58781,
    zoom: 8,
  });
  const [marker, setMarker] = useState(null);
  const dispatch = useDispatch();
  const mapPins = useSelector(
    (state) => (state.Map.mapPins && tripId && state.Map.mapPins[tripId]) || {}
  );
  const [currentMap, setCurrentMap] = useState(null);
  const { getTour } = useTour();
  const store =
    process.env.REACT_APP_ENVIRONMENT === 'production' ||
    process.env.REACT_APP_ENVIRONMENT === 'staging'
      ? 'sessionStorage'
      : 'localStorage';
  /**
   * sets the viewport of the map and then places a marker on the desired location
   *
   * @param {Object} viewport contains the viewport the map should focus to
   * @param {Bool} marker checks whether to place the temporary marker on focus
   */
  const focusPlace = (focusViewport, placeMarker = true) => {
    const disableAnimation =
      getTour().isActiveStep(stepName.MAP_SEARCH_BAR) ||
      getTour().isActiveStep(stepName.ADD_TO_TRIP_BUTTON);
    if (focusViewport) {
      if (currentMap) {
        currentMap.flyTo({
          center: [focusViewport.longitude, focusViewport.latitude],
          // set feature flag for offset
          offset: [0, 150],
          maxDuration: disableAnimation ? 100 : 10000,
          animate: !disableAnimation,
          zoom: focusViewport.zoom || 8,
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
      } else {
        setViewport({
          longitude: focusViewport.longitude,
          latitude: focusViewport.latitude,
          zoom: focusViewport.zoom || 8, // TODO: get zoom from bounding box somehow
        });
      }
      if (placeMarker) {
        setMarker({
          name: 'temporary-marker',
          longitude: focusViewport.longitude,
          latitude: focusViewport.latitude,
        });
      }
    } else {
      // Used to clear temporary pins
      setMarker(null);
    }
  };

  const getCoordsFromMapPin = (mapPinId) => {
    if (mapPinId) {
      const mapPin = mapPins[mapPinId] || {};
      if (mapPin?.lat && mapPin?.long) {
        return {
          lat: mapPin?.lat,
          lng: mapPin?.long,
        };
      }
    }
    return {};
  };

  const setCachedPlacesItem = (placeId, placeDetails) => {
    if (!placeId) return;

    const currPlacesDetails = JSON.parse(window?.[store]?.getItem('gpd')) || {};

    try {
      window?.[store]?.setItem(
        'gpd',
        JSON.stringify({
          ...currPlacesDetails,
          [placeId]: placeDetails,
        })
      );
    } catch (err) {
      const cutDownDetails =
        Object.fromEntries(Object.entries(currPlacesDetails).slice(10)) || {};
      window?.[store].setItem(
        'gpd',
        JSON.stringify({
          ...cutDownDetails,
          [placeId]: placeDetails,
        })
      );
    }
  };

  const getCachedPlacesItem = (placeId) => {
    if (!placeId) return null;

    const currPlacesDetails = JSON.parse(window?.[store]?.getItem('gpd')) || {};

    if (placeId in currPlacesDetails) {
      return currPlacesDetails[placeId] || null;
    }
    return null;
  };

  const getPlaceDetails = async (
    placeId,
    type = 'LOCATION',
    ignorePlaceUpdate = false
  ) => {
    const parameter = {
      // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
      placeId,
      // Specify the return data that you want (optional)
      fields: [
        'name',
        'url',
        'website',
        'photos',
        'rating',
        'user_ratings_total',
        'geometry',
        'address_components',
        'types',
        'opening_hours',
        'formatted_address',
        'formatted_phone_number',
      ],
    };

    const details = await getDetails(parameter);

    const placeDetails = {
      title: details?.name,
      placeId,
      maps: details?.url,
      website: details?.website,
      photo: details?.photos?.length > 0 && details.photos[0].getUrl(),
      photos: details?.photos?.slice(0, 5).map((photo) => photo.getUrl()) || [],
      rating: details?.rating || 0,
      lat: details?.geometry?.location?.lat(),
      long: details?.geometry?.location?.lng(),
      ratingCount: details?.user_ratings_total,
      type,
      addressComponents: details?.address_components,
      formattedAddress: details?.formatted_address,
      formattedPhoneNumber: details?.formatted_phone_number,
      openingHoursObj: details?.opening_hours,
      types: details?.types,
      categoryId: getCategoryIdFromTypes(details?.types) || null,
    };

    setCachedPlacesItem(placeId, placeDetails);

    if (!ignorePlaceUpdate) {
      setPlace(placeDetails);
      focusPlace(
        {
          latitude: details?.geometry?.location?.lat(),
          longitude: details?.geometry?.location?.lng(),
          viewport: details?.geometry?.viewport,
          zoom: type === 'LOCATION' ? 8 : 14,
        },
        false
      );
    }
    return placeDetails;
  };

  /**
   * sets the viewport of the map and opens a popup for the pin
   *
   * @param {Object} pin pindata for the pin to be focused upon.
   */
  const focusPin = (pinId, pinObj) => {
    const pin = mapPins[pinId] || pinObj;
    if (pin?.lat && pin?.long) {
      const pinData = JSON.parse(pin?.pinData || '{}');
      setMarker(null);
      setPlace({
        placeId: pin?.placeId,
        title: pinData?.title,
        maps: pinData?.maps,
        website: pinData?.website,
        photo: pinData?.photo,
        rating: pinData?.rating || 0,
        ratingCount: pinData?.ratingCount,
        description: pinData?.description || '',
        pinned: true,
        pinId: pin.id,
        lat: pin.lat,
        long: pin.long,
        types: pinData?.types,
        type: pin?.type,
        categoryId: pin?.categoryId,
        pinColor: pin?.pinColor,
        formattedAddress: pin?.formattedAddress,
        openingHoursObj: pin?.openingHoursObj,
        addressComponents: pin?.addressComponents,
        formattedPhoneNumber: pin?.formattedPhoneNumber,
      });
      focusPlace(
        {
          longitude: pin.long,
          latitude: pin.lat,
          zoom: pin?.type === 'LOCATION' ? 8 : 14,
        },
        false
      );
    }
  };

  const createMapPinForPlace = async (
    mapPin,
    type = 'LOCATION',
    additionalPlaceDetails = {},
    altTripId = null
  ) => {
    const {
      title,
      description,
      photo,
      rating,
      website,
      maps,
      ratingCount,
      long,
      lat,
      types,
      placeId,
      hotelId,
      categoryId,
    } = mapPin;
    const pin = await dispatch(
      createMapPin({
        context: {
          tripId: tripId || altTripId,
        },
        variables: {
          lat,
          long,
          type,
          placeId,
          hotelId,
          categoryId:
            categoryId ||
            (type === 'ACCOMMODATION' ? 'stay' : getCategoryIdFromTypes(types)),
          pinData: JSON.stringify({
            title,
            photo,
            rating,
            description,
            website,
            maps,
            ratingCount,
            types,
          }),
        },
      })
    );
    focusPin(undefined, {
      ...pin?.payload?.createMapPin,
      // temporary fix until we store all this info in map pin
      ...additionalPlaceDetails,
    });
    return type === 'ACTIVITY' || type === 'ACCOMMODATION'
      ? pin?.payload?.createMapPin
      : pin?.payload?.createMapPin?.id;
  };

  const createMapPinForPlaceId = async (
    placeId,
    type = 'LOCATION',
    altTripId = null
  ) => {
    const mapPin = await getPlaceDetails(placeId, type);
    if (mapPin) {
      const mapPinId = await createMapPinForPlace(
        { ...mapPin, placeId },
        type,
        {},
        altTripId
      );
      return mapPinId;
    }
    return null;
  };

  /**
   * Function to process the places details address into city, state, country and zipcode
   */
  const extractAddressComponents = (addressComponents, defaultAddress) => {
    if (!addressComponents) return {};
    const streetAddressList = ['', '', ''];
    const location = {};

    // parsing the address components to get valid addresses.
    addressComponents.forEach(({ types, long_name: longName }) => {
      if (types.includes('continent')) {
        location.continent = longName;
      } else if (types.includes('country')) {
        location.country = longName;
      } else if (types.includes('postal_code')) {
        location.zipCode = longName;
      } else if (types.includes('administrative_area_level_1')) {
        location.state = longName;
      } else if (types.includes('locality')) {
        location.city = longName;
        streetAddressList[2] = longName;
      } else if (types.includes('route')) {
        streetAddressList[1] = longName;
      } else if (types.includes('street_number')) {
        streetAddressList[0] = longName;
      }
    });

    // assigns the lowest available location information to the street address, else defaults to the place title.
    const streetAddress =
      streetAddressList?.join(' ').trim() ||
      location.state ||
      location.country ||
      defaultAddress;

    return {
      ...location,
      streetAddress,
    };
  };

  /**
   * calls places details API, creates map pin and extracts address for a place id.
   * To be used when user selects an option on the places searchbar autocomplete from the itinerary.
   */
  const handleLocationSelect = async (
    placeId,
    mapPinId,
    type = 'ACCOMMODATION'
  ) => {
    const placeDetails = await getPlaceDetails(placeId);
    if (!placeDetails) return;

    let mapPin;

    if (mapPinId) {
      const {
        title,
        description,
        photo,
        rating,
        website,
        maps,
        ratingCount,
        long,
        lat,
        types,
      } = placeDetails;
      const updatedMapPin = await dispatch(
        updateMapPin({
          context: {
            tripId,
          },
          variables: {
            id: mapPinId,
            lat,
            long,
            type,
            placeId,
            categoryId:
              type === 'ACCOMMODATION' ? 'stay' : getCategoryIdFromTypes(types),
            pinData: JSON.stringify({
              title,
              photo,
              rating,
              description,
              website,
              maps,
              ratingCount,
              types,
            }),
          },
        })
      );
      mapPin =
        type === 'ACTIVITY' || type === 'ACCOMMODATION'
          ? updatedMapPin?.payload?.updateMapPin
          : updatedMapPin?.payload?.updateMapPin?.id;
    } else {
      mapPin = await createMapPinForPlace({ ...placeDetails, placeId }, type);
    }

    const { addressComponents = [], title } = placeDetails;
    // eslint-disable-next-line consistent-return
    return {
      ...(extractAddressComponents(addressComponents, title) || {}),
      title,
      mapPin,
    };
  };

  const handleImageError = async () => {
    const newPlaceDetails = await getPlaceDetails(
      place.placeId,
      'LOCATION',
      true
    );
    const { photo } = newPlaceDetails;
    setPlace((oldPlace) => ({
      ...oldPlace,
      photo,
    }));
    const pin = mapPins[place.pinId];
    const pindata = JSON.parse(pin?.pinData || '{}');
    dispatch(
      updateMapPin({
        context: {
          tripId,
        },
        variables: {
          ...pin,
          pinData: JSON.stringify({
            ...pindata,
            photo,
          }),
        },
      })
    );
    return photo;
  };

  const mapContext = {
    place,
    setPlace,
    viewport,
    setViewport,
    focusPlace,
    focusPin,
    marker,
    setMarker,
    getPlaceDetails,
    handleImageError,
    createMapPinForPlaceId,
    createMapPinForPlace,
    handleLocationSelect,
    extractAddressComponents,
    setCurrentMap,
    getCoordsFromMapPin,
    setCachedPlacesItem,
    getCachedPlacesItem,
    currentMap,
  };

  return (
    <MapContext.Provider value={mapContext}>{children}</MapContext.Provider>
  );
}

export const useMapUtils = () => {
  return useContext(MapContext);
};
